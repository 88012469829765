import { ReactNode } from 'react';

const CustomCheckbox = ({
  checked,
  // onChange,
  label,
  handleClick, //onClick={handleClick}
}: {
  checked: boolean;
  // onChange: (e?: any) => void;
  handleClick: (e?: any) => void;
  label: ReactNode;
}) => (
  <div className="my-4 mr-2 flex cursor-pointer items-center">
    <div className="relative">
      <input type="checkbox" className="hidden" checked={checked} />
      <div
        className={`h-5 w-5 rounded-full border-2 ${checked ? 'border-[#4DBAC0] bg-[#4DBAC0]' : 'border-[#9ba1a8]'}`}
      >
        {checked && (
          <svg
            className="absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 transform text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        )}
      </div>
    </div>
    {label && (
      <span onClick={handleClick} className="ml-2 text-sm">
        {label}
      </span>
    )}
  </div>
);

export default CustomCheckbox;
