import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import VerifyCodeWrap from '../Wrap';
import { useRequest } from 'ahooks';
import { postUserPublicWebSmsCodeVerify } from '@/lib/services/gonggongjiekou';
import { useAuthProvider } from '@/lib/context';
import { formatPhoneNumberFull } from '@/components/logreg/utils';

// const COUNT_DOWN_LIMIT = 30;

const VerifyCode = ({
  phoneNumber,
  token,
  // setContent,
  getResult,
  goBack,
  thirdUserId,
}: {
  phoneNumber?: string;
  token?: string;
  setContent?: () => void;
  getResult?: (v: any) => void;
  [k: string]: any;
}) => {
  const t = useTranslations('Registration');

  const [submitError, setSubmitError] = useState<boolean>(false);
  const [codes, setResult] = useState<string[]>([]);
  const { handleLogin } = useAuthProvider();

  // 验证短信验证码(web)
  const { loading, run: SmsCodeVerify } = useRequest(postUserPublicWebSmsCodeVerify, {
    manual: true,
    onSuccess(res) {
      if (res?.code === 0) {
        getResult?.(res.data.ticket);
        // setContent?.();
        if (res.data.token) {
          handleLogin?.(res.data?.token);
          window.location.reload();
        }
      } else {
        setSubmitError(true);
      }
    },
  });

  const handleSubmit = () => {
    const code = codes.join('');
    console.log('Submitted code:', code);

    SmsCodeVerify({
      phone: phoneNumber?.replace(/\D/g, '') as string,
      smsCode: code,
      operateType: 'LOGIN_VERIFY', //登录，仅验证手机号与短信验证码有效性
      thirdUserId,
      thirdPlatformType: 'google', // 目前只有google
    });
  };

  return (
    <div className={`mx-auto h-fit !w-[428px] rounded-3xl bg-white p-6 shadow-lg`}>
      <h2 className="mb-4 text-center text-lg font-semibold">{t('verifycode')}</h2>
      <p className="mb-0 text-center text-sm font-normal text-[#80878E]">
        {t('reminder1') + formatPhoneNumberFull(phoneNumber + '')}
      </p>
      <p className="mb-0 text-center text-sm font-normal text-[#80878E]">{t('reminder2')}</p>
      <p className="mb-2 text-center text-sm font-normal text-[#80878E]">{t('reminder3')}</p>

      <VerifyCodeWrap
        phoneNumber={phoneNumber || ''}
        token={token || ''}
        handleSubmit={handleSubmit}
        setResult={setResult}
        goBack={goBack}
        loading={loading}
        submitError={submitError}
        setSubmitError={setSubmitError}
      />
    </div>
  );
};

export default VerifyCode;
