import React, { ReactNode } from 'react';

const CustomButton = ({
  children,
  color,
  icon: Icon,
  iconColor = 'white',
  iconSize = '20',
  ref,
  onClick,
  disabled,
}: {
  children: ReactNode;
  color: string;
  icon: React.ComponentType<{ size?: string; color?: string }>;
  iconColor?: string;
  iconSize?: string;
  ref?: any;
  onClick?: any;
  disabled?: boolean;
}) => (
  <button
    ref={ref}
    onClick={onClick}
    disabled={disabled}
    className={`relative flex w-full items-center justify-center rounded-xl px-4 py-2.5 transition-opacity hover:opacity-90 ${color} `}
  >
    {Icon && <Icon size={iconSize} color={iconColor} />}
    <span className="ml-3 text-[16px] font-medium">{children}</span>
  </button>
);

export default CustomButton;
