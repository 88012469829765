// const countryOptions = ['RU', 'BY', 'KZ', 'UZ', 'KG', 'TM', 'TJ']
// 俄罗斯，白俄罗斯，哈萨克斯坦，乌兹别克斯坦，吉尔吉斯斯坦，土库曼斯坦，塔吉克斯坦

import { useTranslations } from 'next-intl';

const useCountryCodes = () => {
  const t = useTranslations('Registration');

  const CountryCodes = [
    { country: t('kazakhstan'), code: '+7', flag: 'kazakhstan', abbr: 'KZ' }, // 哈萨克斯坦
    // { country: t('armenia'), code: '+374', flag: 'armenia', abbr: 'AM' },
    // { country: t('belarus'), code: '+375', flag: 'belarus', abbr: 'BY' },
    // { country: t('uzbekistan'), code: '+998', flag: 'uzbekistan', abbr: 'UZ' },
    // { country: t('russia'), code: '+7', flag: 'russia', abbr: 'RU' },
  ];

  return {
    CountryCodes,
  };
};

export default useCountryCodes;
