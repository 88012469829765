import { useTranslations } from 'next-intl';
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
// import { GoogleIcon } from '../../commons/GoogleIcon';
import { useRequest } from 'ahooks';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { getUserPublicPhoneExist } from '@/lib/services/gonggongjiekou';
import Flag from '../../commons/Flag';
import CustomButton from '../../commons/CustomButton';
import { FacebookIcon } from '../../commons/FacebookIcon';
import AlertIcon from '@/components/cart/AlertIcon';
// import message from '@/components/commons/Message';
import { formatPhoneNumber } from '../../utils';
import { NewLoading } from '@/components/commons';
import useCountryCodes from '../../commons/CountryCodes';
import SelectIcon from '../../icons/SelectIcon';
import GoogleLoginButton from './GoogleLogin';
import { postUserPublicWebGoogleLogin } from '@/lib/services/googledenglu';
import { useAuthProvider } from '@/lib/context';
import storage from '@/shared/utils/storage';
import { message } from 'antd';
import MaskInput from './ImprovedMaskInput';

// 俄罗斯 号码格式 区号+7位： (800) 555-35-35  区号+7位

export default function PhoneNumber(props: any) {
  const t = useTranslations('Login');
  const { getResult, setRegisttarionPage, setContentNext, getThirdUserId, thirdUserId } = props;

  const { handleLogin } = useAuthProvider();

  const [isNotRegistered, setIsNotRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const { run: checkExist } = useRequest(getUserPublicPhoneExist, {
    manual: true,
    onSuccess(res: { code: number; msg: string; data: boolean }) {
      console.log(res);
      // setRegisttarionPage({
      //   countryCode: selectedCountry.code,
      //   phoneNumber: phoneNumber,
      // }); // 调试用，要删掉
      // return;

      if (res?.data === true) {
        //存在,提示该手机号已存在
        console.log(selectedCountry.code + phoneNumber);

        getResult(selectedCountry.code + phoneNumber);
        setTimeout(() => {
          setContentNext();
          setLoading(false);
        }, 200);
      } else {
        setTimeout(() => {
          setIsNotRegistered(true);
          setRegisttarionPage({
            countryCode: selectedCountry.code,
            phoneNumber: phoneNumber,
            country: selectedCountry.abbr,
          });
          setLoading(false);
        }, 1500);
      }
    },
  });

  const { CountryCodes } = useCountryCodes();

  const [selectedCountry, setSelectedCountry] = useState(CountryCodes[0]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isShort, setIsShort] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<any>(null);

  const handlePhoneChange = useCallback(
    (e: any) => {
      // const value = e.target.value.replace(/\D/g, '')
      setIsNotRegistered(false);
      setIsShort(false);
      const value = e.target.value;

      let _isValid = true;
      if (value.replace(/\D/g, '').length < 10) {
        setIsShort(true);
      }
      if (value.replace(/\D/g, '').length >= 10) {
        _isValid = isValidPhoneNumber(`${selectedCountry.code} ${value}`, selectedCountry.flag as any);
        console.log(_isValid);
        if (value.replace(/\D/g, '').length > 10) {
          // 11和14位，libphonenumber-js对哈萨克手机号校验不准，姑且这样处理
          _isValid = false;
        }
      }
      setIsValid(_isValid);

      console.log(value);
      const number = formatPhoneNumber(value);
      console.log(number);

      setPhoneNumber(number);
    },
    [selectedCountry.code, selectedCountry.flag],
  );

  const toggleDropdown = () => setIsOpen((v) => !v);

  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false);
    console.log(e);
    // onBlur?.(e);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTimeout(() => {
          setIsOpen(false);
        }, 200);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const disabled = useMemo(() => {
    return !isValid || isShort || loading;
  }, [isShort, isValid, loading]);

  return (
    <div className="logreg-inputs inset-0 flex min-h-fit min-w-fit items-center justify-center bg-opacity-50">
      <div className="w-120 rounded-3xl bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-center text-[18px] font-semibold">{t('entrance')}</h2>
        <div className="w-full space-y-4">
          <div
            className={`relative box-border w-full rounded-xl border-[1px] border-[var(--theme-color-blue2)] bg-[#F5F6F6] px-4 py-2 ${isValid ? (isFocused ? '!border-[#4DBAC0]' : '!border-[var(--theme-color-blue2)]') : '!border-[#FF3141]'}`}
          >
            <label className="mb-1 block text-sm font-medium text-black">{t('yourphonenumber')}</label>

            <div className="relative flex h-10 w-full" ref={dropdownRef}>
              <div
                onClick={toggleDropdown}
                className="relative flex w-fit cursor-pointer items-center justify-between rounded-xl border-0 bg-transparent py-2 text-left focus:border-[#4DBAC0] focus:outline-none focus:ring-1 focus:ring-[#4DBAC0]"
              >
                <span className="flex w-fit items-center">
                  <div className="flex h-8 w-8 items-center justify-center">
                    <Flag country={selectedCountry.flag as any} />
                  </div>
                  <span className="mx-2 text-black">{selectedCountry.code}</span>
                  <span
                    className={`h-2 w-2 transform border-r-2 border-t-2 border-black ${
                      isOpen ? '-rotate-45' : 'rotate-135'
                    } inline-block transition-transform duration-200`}
                  ></span>
                </span>
              </div>

              {/* <div className="relative flex-1">
                <input
                  type="tel"
                  // placeholder={isFocused ? '' : '771 000 00 00'}
                  // placeholder={'771 000 00 00'}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  className="real-input left-0 top-0 h-full w-60 rounded-xl border-none bg-transparent pl-4 focus:outline-none focus:ring-0"
                />
                <span className="absolute left-0 top-0 h-full w-full text-gray-400">{'771 000 00 00'}</span>
                <div className="mask"></div>
                <span className="placeholder">771 000 00 00</span>
              </div> */}

              <MaskInput
                value={phoneNumber}
                onChange={handlePhoneChange}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
              />
            </div>
            {isOpen && (
              <div className="diy-scrollbar absolute left-0 top-20 z-10 mt-1 max-h-52 w-full scale-100 overflow-auto rounded-xl border border-[#4DBAC0] bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div className="bg-transparent px-3 py-2 text-lg text-black">{t('countrycode')}</div>
                {CountryCodes.map((country, index) => (
                  <div
                    key={index}
                    className="relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-teal-50"
                    onClick={() => {
                      console.log(country);

                      setSelectedCountry(country);
                    }}
                  >
                    <span className="flex items-center justify-between">
                      <div className="flex items-center justify-start">
                        <div className="flex h-5 w-8 items-center justify-center">
                          <Flag country={country.flag as any} />
                        </div>

                        {country.country}
                        {country.code}
                      </div>

                      {selectedCountry.flag === country.flag && <SelectIcon />}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>

          {!isValid && (
            <div className="flex items-center px-0 py-0" role="alert">
              <AlertIcon />
              <div className="red ml-1 text-[13px]"> {t('InvalidPhoneNumber')}</div>
            </div>
          )}
          {isNotRegistered && (
            <div className="flex items-center px-0 py-0" role="alert">
              <AlertIcon />
              <div className="red ml-1 text-[13px]"> {t('notregister')}</div>
            </div>
          )}

          <button
            className={`w-full rounded-xl py-3 text-[16px] font-medium transition duration-150 ease-in-out hover:opacity-90 ${disabled ? 'bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : 'bg-[#4DBAC0] text-black'}`}
            disabled={disabled}
            onClick={() => {
              if (isValid) {
                checkExist({ phone: (selectedCountry.code + phoneNumber).replace(/\D/g, '') });
                setLoading(true);
              }
            }}
          >
            {loading ? <NewLoading type="special" /> : t('enter')}
          </button>

          {thirdUserId && (
            <div className="pb-2">
              <p className="text-[14px] text-[#ff3141]">{t('googleLoginPhoneInput')}</p>
            </div>
          )}

          <>
            <div className="my-4 flex items-center">
              <div className="flex-grow border-t border-[#4DBAC0]"></div>
              <span className="mx-4 flex-shrink text-[12px] font-medium text-black">{t('or')}</span>
              <div className="flex-grow border-t border-[#4DBAC0]"></div>
            </div>

            <div className="space-y-4">
              <GoogleLoginButton
                onCancel={() => {}}
                onSuccess={(response) => {
                  setLoading(true);
                  postUserPublicWebGoogleLogin({ idToken: response.credential })
                    .then((res) => {
                      if (res.code === 1001) {
                        // res.data.thirdUserId
                        if (res.data.thirdUserId) {
                          getThirdUserId(res.data.thirdUserId);
                          if (res.data.email) {
                            storage.set('google-email-temp', res.data.email);
                          }
                        }
                      }
                      if (res.data.token) {
                        handleLogin?.(res.data?.token);
                        window.location.reload();
                      }
                      setLoading(false);
                    })
                    .catch(() => {
                      message.error(t('failed'));
                      setLoading(false);
                    });
                }}
                loadingOuter={loading}
                disabled={!!thirdUserId}
              />

              <CustomButton color="bg-[#4267B2] text-white" icon={(props: any) => <FacebookIcon {...props} />}>
                {t('ContinuewithFacebook')}
              </CustomButton>
            </div>

            <div className="mt-5 hidden cursor-pointer text-center" onClick={setRegisttarionPage}>
              {t('registernow')}
            </div>
          </>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}
