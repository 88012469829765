// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** 计算商品SKU实际价格 product-service
注意: 用户已登录和未登录价格计算不同 POST /product/public/price/calculate */
export async function postProductPublicPriceCalculate(
  body: {
    skuId: number;
    quantity: number;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: number }>('/product/public/price/calculate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 相关商品列表 product-service
商品详情页面底部相关商品列表 GET /product/public/relate/product/${param0} */
export async function getProductPublicRelateProductProductId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicRelateProductProductIdParams,
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  return request<{
    code: number;
    msg: string;
    data: {
      total: string;
      pageNum: number;
      pageSize: number;
      pages: number;
      list: API.WebProductItemVo[];
      size: number;
      startRow: string;
      endRow: string;
      prePage: number;
      nextPage: number;
      isFirstPage: boolean;
      isLastPage: boolean;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      navigatePages: number;
      navigatepageNums: number[];
      navigateFirstPage: number;
      navigateLastPage: number;
    };
  }>(`/product/public/relate/product/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 商品详情 product-service GET /product/public/web/product/detail/${param0} */
export async function getProductPublicWebProductDetailProductId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicWebProductDetailProductIdParams,
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  return request<{ code: number; msg: string; data: API.WebProductDetailVo }>(
    `/product/public/web/product/detail/${param0}`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 商品评价列表 product-service
商品详情查看更新评价列表弹窗 GET /product/public/web/review/list/${param0} */
export async function getProductPublicWebReviewListProductId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicWebReviewListProductIdParams,
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  return request<{
    code: number;
    msg: string;
    data: {
      total: number;
      pageNum: number;
      pageSize: number;
      pages: number;
      list: {
        id: number;
        customerName: string;
        customerAvatar: string;
        images: string[];
        content: string;
        advantages: string;
        disadvantages: string;
        score: number;
        createdAt: string;
        self: boolean;
      }[];
    };
  }>(`/product/public/web/review/list/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 商品分期方案 product-service
商品详情页粗体商品价格右侧 银行分期信息,根据银行分期配置结合当前商品价格计算分期参考方案列表
需要用户登录鉴权 GET /product/web/bank/installment/scheme */
export async function getProductWebBankInstallmentScheme(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductWebBankInstallmentSchemeParams,
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: {
      bankId: number;
      bankName: string;
      installmentScheme: { installmentName: string; monthPayment: number; serviceCharge: number }[];
    }[];
  }>('/product/web/bank/installment/scheme', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 保存客户商品浏览历史 PUT /product/web/product/browse/history/${param0} */
export async function putProductWebProductBrowseHistoryProductId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.putProductWebProductBrowseHistoryProductIdParams,
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  return request<API.BaseResult>(`/product/web/product/browse/history/${param0}`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 收藏商品 product-service
需要用户登录鉴权 PUT /product/web/product/collect/${param0} */
export async function putProductWebProductCollectProductId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.putProductWebProductCollectProductIdParams,
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  return request<API.BaseResult>(`/product/web/product/collect/${param0}`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 取得商品SKU ID product-service
根据用户选择的商品规格(如颜色,大小等)获得该SKU详细信息(包括SKU ID,价格),其中SKUID 加入商品到购车时需要,
注意, POST /product/web/product/sku/id/${param0} */
export async function postProductWebProductSkuIdProductId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postProductWebProductSkuIdProductIdParams,
  body: { attributeName: string; attributeValue: string }[],
  options?: { [key: string]: any },
) {
  const { productId: param0, ...queryParams } = params;
  return request<string[]>(`/product/web/product/sku/id/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 评价删除(限评论作者)  product-service
仅限用户自已发表的评价 DELETE /product/web/review/delete/${param0} */
export async function deleteProductWebReviewOpenApiDeleteReviewsId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.deleteProductWebReview_openAPI_deleteReviewsIdParams,
  options?: { [key: string]: any },
) {
  const { reviewsId: param0, ...queryParams } = params;
  return request<API.BaseResult>(`/product/web/review/delete/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 评价编辑(限评论作者) product-service
仅限用户自已发表的评价 POST /product/web/review/edit */
export async function postProductWebReviewEdit(
  body: {
    /** 修改时必传 */
    id: number;
    shopOrderId?: number;
    productId?: string;
    productName?: string;
    skuId?: number;
    /** 1-5 */
    score: number;
    /** 大字段 */
    content: string;
    advantages: string;
    disadvantages: string;
    images?: string[];
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/product/web/review/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 评价举报 POST /product/web/review/report */
export async function postProductWebReviewReport(
  body: {
    reviewsId: number;
    type: string;
    reportReason: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/product/web/review/report', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
