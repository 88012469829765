// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** google登录 POST /user/public/web/google/login */
export async function postUserPublicWebGoogleLogin(
  body: {
    /** google登录后获取到的token */
    idToken: string;
  },
  options?: { [key: string]: any },
) {
  return request<{
    code: number;
    msg: string;
    data: { token: string; thirdUserId: string; email: string };
  }>('/user/public/web/google/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
