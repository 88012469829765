import React, { useState, useRef, useLayoutEffect } from 'react';

const ImprovedMaskInput = ({
  value,
  onChange,
  handleBlur,
  handleFocus,
  placeholder = '771 000 00 00',
  className = '',
}: any) => {
  // const [value, setValue] = useState('');
  const textRef = useRef(null);
  const placeholderRef = useRef(null);
  const [inputWidth, setInputWidth] = useState(0);

  // 使用 useLayoutEffect 确保在浏览器绘制之前更新遮罩宽度
  useLayoutEffect(() => {
    if (textRef.current && value) {
      // 创建一个 span 来精确测量输入文本的宽度
      const span = document.createElement('span');
      span.style.visibility = 'hidden';
      span.style.position = 'absolute';
      span.style.whiteSpace = 'pre';
      // 复制输入框的字体样式
      const styles = window.getComputedStyle(textRef.current);
      span.style.font = styles.font;
      span.style.letterSpacing = styles.letterSpacing;
      span.textContent = value;

      document.body.appendChild(span);
      const width = span.getBoundingClientRect().width;
      document.body.removeChild(span);

      setInputWidth(width);
    } else {
      setInputWidth(0);
    }
  }, [value]);

  return (
    <div className={`relative inline-block ${className}`}>
      {/* 实际输入框 */}
      <input
        ref={textRef}
        type="text"
        value={value}
        onChange={(e) => onChange(e)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="relative z-20 w-full rounded-md border-0 bg-transparent px-3 py-2 outline-none"
      />

      {/* 占位符容器 */}
      <div ref={placeholderRef} className="pointer-events-none absolute inset-0 flex items-center px-3 py-2">
        {/* 遮罩 */}
        <div
          className="absolute left-3 top-0 z-10 h-full bg-[#F5F6F6] transition-all duration-100"
          style={{ width: `${inputWidth}px` }}
        />

        {/* 占位符文本 */}
        <span className="z-0 select-none text-gray-400">{placeholder}</span>
      </div>
    </div>
  );
};

export default ImprovedMaskInput;
