// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** 修改密码 POST /user/web/change/password */
export async function postUserWebChangePassword(
  body: {
    password: string;
    captcha: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/web/change/password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 登录后用户信息 GET /user/web/customer/profile */
export async function getUserWebCustomerProfile(options?: { [key: string]: any }) {
  return request<{
    code: number;
    msg: string;
    data: {
      id: number;
      email: string;
      avatar: string;
      name: string;
      nickname: string;
      birthday: string;
      gender: string;
      mobile: string;
      iin: string;
      documentImage: { type: string; photo: string }[];
      validityPeriod: string;
      documentNumber: string;
      userType: string;
      userRelation: string;
      status: string;
      token: string;
      rsaPublicKey: string;
      notifyFlag: boolean;
      country: string;
    };
  }>('/user/web/customer/profile', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 登录后用户信息(Shadow) GET /user/web/customer/profile/shadow */
export async function getUserWebCustomerProfileShadow(options?: { [key: string]: any }) {
  return request<{
    code: number;
    msg: string;
    data: {
      id: number;
      email: string;
      avatar: string;
      name: string;
      nickname: string;
      birthday: string;
      gender: string;
      mobile: string;
      iin: string;
      documentImage: { type: string; photo: string }[];
      validityPeriod: string;
      documentNumber: string;
      userType: string;
      userRelation: string;
      status: string;
      token: string;
      rsaPublicKey: string;
      notifyFlag: boolean;
      country: string;
    };
  }>('/user/web/customer/profile/shadow', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 帐号注销 注意: 帐号一旦注销,将不能再次登录,即刻生效 DELETE /user/web/logout */
export async function deleteUserWebLogout(
  body: {
    /** 必填 */
    captcha: string;
    reason?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.BaseResult>('/user/web/logout', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改个人信息 POST /user/web/profile/edit */
export async function postUserWebProfileEdit(body: API.WebCustomerProfileEditVo, options?: { [key: string]: any }) {
  return request<{
    code: number;
    msg: string;
    data: {
      id: number;
      email: string;
      avatar: string;
      name: string;
      nickname: string;
      birthday: string;
      gender: string;
      mobile: string;
      iin: string;
      documentImage: { type: string; photo: string }[];
      validityPeriod: string;
      documentNumber: string;
      userType: string;
      userRelation: string;
      status: string;
      token: string;
      rsaPublicKey: string;
      notifyFlag: boolean;
      country: string;
    };
  }>('/user/web/profile/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
