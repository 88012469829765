import '@/styles/globals.css';
import 'nprogress/nprogress.css';
import { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { NextIntlClientProvider } from 'next-intl';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useRouter as useRouterNav } from 'next/navigation';
import NProgress from 'nprogress';
import { NextPage } from 'next';
import { AuthProvider, CartProvider, CollectProvider, ConfigProvider, useAuthProvider } from '@/lib/context'; // useAuthProvider
import storage from '@/shared/utils/storage';
import { USER_LOGIN_TOKEN } from '@/shared/constants';
import { Toaster } from '@/components/ui/toaster';
import EM from '@/shared/utils/EM';
// import message from '@/components/commons/Message';
import Login from '@/components/logreg/Login';
import { usePathname } from 'next/navigation';

NProgress.configure({ showSpinner: false });

type PageProps = {
  messages: IntlMessages;
  now: number;
  userAgent: string;
};

export type NextPageWithLayout<P = unknown> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout<P = unknown> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
  pageProps: PageProps;
  userAgent?: string;
  head?: any;
  props?: any;
};

export default function App({ Component, pageProps }: AppPropsWithLayout<PageProps>) {
  const router = useRouter();
  const routerNavigate = useRouterNav();
  const [token, setToken] = useState<string>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  const { handleLogout } = useAuthProvider();

  // token过期打开弹层，排除 /react_native_map
  const pathname = usePathname();

  const tokenExpireRef = useRef(0);
  const [isLoginLayerOpen, setLoginLayerOpen] = useState(false);
  const [isTokenExpiredCase, setTokenExpiredCase] = useState(false);

  useEffect(() => {
    const fn = () => {
      console.log('toLogin');
      // alert('toLogin');
      setLoginLayerOpen(true); // 未登录，打开登录窗
    };
    EM.on('toLogin', fn);

    return () => {
      // 清理事件监听
      EM.off('toLogin', fn);
    };
  }, []);

  useEffect(() => {
    const handleTokenExpired = () => {
      // alert('tokenExpired');
      setTokenExpiredCase(true);
      if (!tokenExpireRef.current) {
        tokenExpireRef.current = 1;

        if (!['/react_native_map', '/react_native_pay'].includes(pathname)) {
          handleLogout?.(); //  token过期，做退出登录处理
          routerNavigate.replace('/');
          setLoginLayerOpen(true); // token过期，打开登录窗
        }

        setTimeout(() => {
          tokenExpireRef.current = 0;
        }, 3000); // 3秒后恢复到初始状态，可以继续接收token过期消息
      }
    };
    EM.on('tokenExpired', handleTokenExpired);

    return () => {
      // 清理事件监听
      EM.off('tokenExpired', handleTokenExpired);
    };
  }, [handleLogout, pathname, routerNavigate]);

  useEffect(() => {
    const handleNotLogin = () => {
      if (!['/react_native_map', '/react_native_pay'].includes(pathname)) {
        handleLogout?.(); // 未登录，也做退出登录处理
        routerNavigate.replace('/');
        setLoginLayerOpen(true);
      }
    };
    EM.on('notLogin', handleNotLogin);

    return () => {
      // 清理事件监听
      EM.off('notLogin', handleNotLogin);
    };
  }, [handleLogout, pathname, routerNavigate]);

  useEffect(() => {
    const handleStart = () => {
      NProgress.start();
    };

    const handleStop = () => {
      NProgress.done();
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  const getAuthToken = useCallback((token?: string) => {
    setToken(token);
  }, []);

  useEffect(() => {
    if (storage.get(USER_LOGIN_TOKEN)) {
      setToken(storage.get(USER_LOGIN_TOKEN));
    }
  }, []);

  return (
    <NextIntlClientProvider
      // To achieve consistent date, time and number formatting
      // across the app, you can define a set of global formats.
      formats={{
        dateTime: {
          short: {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          },
        },
      }}
      messages={pageProps.messages}
      // Providing an explicit value for `now` ensures consistent formatting of
      // relative values regardless of the server or client environment.
      now={new Date(pageProps.now)}
      // Also an explicit time zone is helpful to ensure dates render the
      // same way on the client as on the server, which might be located
      // in a different time zone.
      timeZone="Asia/Chita"
      locale={router.locale ?? 'ru'}
    >
      <ConfigProvider>
        <CollectProvider value={{ token }}>
          <CartProvider value={{ token }}>
            <AuthProvider value={{ getAuthToken, setToken }}>
              {getLayout(<Component {...pageProps} />)}
              {!['/react_native_map', '/react_native_pay'].includes(pathname) && (
                <Login
                  triggerIcon={<></>}
                  isLoginOpenOuter={isLoginLayerOpen}
                  setLoginOpenOuter={setLoginLayerOpen}
                  windowReload={isTokenExpiredCase} // 专门处理token过期时，跳转到首页后，用户不登录，直接关闭弹窗后，的问题，页面刷新解决。（不能删掉）
                />
              )}
            </AuthProvider>
            <Toaster />
          </CartProvider>
        </CollectProvider>
      </ConfigProvider>
    </NextIntlClientProvider>
  );
}
