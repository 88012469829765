import axios, { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';
import storage from '@/shared/utils/storage';
import { USER_LOGIN_TOKEN } from '@/shared/constants';
import EM from '@/shared/utils/EM';
// import { message } from 'antd';
const isDev = process.env.NODE_ENV === 'development';
// console.log(process.env.NODE_ENV);

// 新增：统一的token更新函数
const updateToken = (token: string) => {
  storage.set(USER_LOGIN_TOKEN, token);
  instance.defaults.headers.common['Authorization'] = token;
  if (isDev) {
    console.log('Token updated:', token);
  }
};

const { publicRuntimeConfig } = getConfig();

const instance = axios.create({
  baseURL: publicRuntimeConfig.NEXT_PUBLIC_API_HOST,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 150000, // 前端设置请求超时
});

instance.interceptors.request.use(
  (config) => {
    const token = storage.get(USER_LOGIN_TOKEN); // 需要进一步验证，这种方式是否会导致旧的token被缓存
    if (isDev) {
      // alert(token);
    }
    if (token) {
      config.headers['Authorization'] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    if (
      response.config.url &&
      ['/user/web/customer/profile', '/user/web/customer/profile/shadow', '/user/web/profile/edit'].includes(
        response.config.url,
      )
    ) {
      // 更新token
      if (response.data.data?.token) {
        console.log(response);
        updateToken(response.data.data?.token);

        setTimeout(() => {
          EM.emit('update_isEnabled_state', true); // token更新后，就立即更新isEnabled状态
        }, 1000);
      }
    }
    return response;
  },
  (error) => {
    console.log(error);
    // error?.response?.data?.code === 20001   error?.response?.data?.code === 403  ===> errror?.response.status===401
    if (
      (error?.response?.data?.code === 403 || error?.response?.data?.code === 401) &&
      error.config.url !== '/user/web/customer/profile/shadow'
    ) {
      // Token失效
      // token失效，也要排除个人信息接口，AuthProvider里判断isEnabled
      EM.emit('tokenExpired', true);
      storage.del(USER_LOGIN_TOKEN);
      // instance.defaults.headers.common['Authorization'] = ''; // 清除内存中的过期token,未生效。使用windows.reload()解决
      // message.error(error?.response?.data?.msg);
    }
    if (error?.response?.data?.code === 20001 && error.config.url !== '/user/web/customer/profile/shadow') {
      // 未登录
      // 排除个人信息接口，AuthProvider里判断isEnabled
      EM.emit('notLogin', true);
      storage.del(USER_LOGIN_TOKEN);
      // instance.defaults.headers.common['Authorization'] = '';
      // message.error(error?.response?.data?.msg);
    }
    if (typeof window === 'object') {
      // message.destroy();
      // message.error('Sorry, something went wrong. Please try again.');
      // message.error(error.message);
    }
    return Promise.reject(error);
  },
);

export function updateAuthorization(token: string) {
  instance.defaults.headers.common['Authorization'] = `${token}`;
}

export function getAuthorization() {
  const token = storage.get(USER_LOGIN_TOKEN);
  return token ? `${token}` : null;
}

const request = function <T = any>(
  url: string,
  opts: AxiosRequestConfig & { requestType?: 'json' | 'form' } = { method: 'GET' },
) {
  const options = { ...opts };
  // 兼容form data文件上传的情况
  const { requestType, ...rest } = opts;
  if (requestType === 'form') {
    options.headers = {
      ...(rest.headers || {}),
      'Content-Type': 'multipart/form-data',
    };
  }
  return new Promise<T>((resolve, reject) => {
    instance
      .request<T>({ url, ...options })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export default request;
