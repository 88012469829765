// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** 文章详情 GET /product/public/article/${param0} */
export async function getProductPublicArticleId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getProductPublicArticleIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<{ code: number; msg: string; data: API.WebArticleDetailVo }>(`/product/public/article/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
