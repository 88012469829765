// 格式化输入的电话号码
const formatPhoneNumber = function (value: string) {
  // Remove non-digit characters
  let number = value.replace(/\D/g, '');

  // Trim to 10 digits if longer
  number = number.substring(0, 15);

  // Apply formatting
  if (number.length > 10) {
    console.log('大于10');
    number = number.replace(/(\d{3})(\d{3})(\d{2})(\d{0,2})(\d{0,5})/, '$1 $2 $3 $4 $5');
  } else if (number.length >= 9) {
    number = number.replace(/(\d{3})(\d{3})(\d{2})(\d{1})/, '$1 $2 $3 $4');
  } else if (number.length >= 8) {
    number = number.replace(/(\d{3})(\d{3})(\d{2})/, '$1 $2 $3');
  } else if (number.length > 6) {
    number = number.replace(/(\d{3})(\d{3})(\d{0,3})/, '$1 $2 $3');
  } else if (number.length > 3) {
    number = number.replace(/(\d{3})(\d{0,3})/, '$1 $2');
  }

  // Update input value
  return number;
};

const formatPhoneNumber11 = function (value: string) {
  // Remove non-digit characters
  let number = value.replace(/\D/g, '');

  // Trim to 10 digits if longer
  number = number.substring(0, 11);

  // Apply formatting
  if (number.length >= 11) {
    number = number.replace(/(\d{1})(\d{3})(\d{3})(\d{0,2})(\d{0,2})/, '$1 $2 $3 $4 $5');
  } else if (number.length > 7) {
    number = number.replace(/(\d{1})(\d{3})(\d{3})(\d{0,3})/, '$1 $2 $3 $4');
  } else if (number.length > 4) {
    number = number.replace(/(\d{1})(\d{3})(\d{0,3})/, '$1 $2 $3');
  } else if (number.length > 1) {
    number = number.replace(/(\d{1})(\d{0,3})/, '$1 $2');
  }

  // Update input value
  return '+' + number;
};

// 格式化电话号码()
const formatPhoneNumberFull = function (phoneNumber: string) {
  // 如果输入的号码已经包含非数字字符，我们假设它已经被格式化，直接返回
  if (/\D/.test(phoneNumber)) {
    return phoneNumber;
  }

  // 如果是纯数字，我们进行格式化
  const cleaned = phoneNumber.replace(/\D/g, '');

  // 假设是俄罗斯的号码格式（11位）
  if (cleaned.length === 11) {
    return `+ ${cleaned[0]} (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)} ${cleaned.slice(7, 9)} ${cleaned.slice(9)}`;
  }

  // 如果不是11位，我们返回原始输入
  return phoneNumber;
};

const validatePasswordIntel = (password: any): { en: string; ru: string; kk: string } => {
  // 至少8个字符
  if (password.length < 8) {
    // return "密码长度不足8个字符";
    return {
      en: 'The password must consist of 8 characters',
      ru: 'Пароль обязательно должен состоять из 8 символов',
      kk: 'Құпия сөз 8 таңбадан тұруы керек',
    };
  }

  // 至少一个大写字母
  if (!/[A-Z]/.test(password)) {
    // return "密码必须包含至少一个大写字母";
    return {
      en: 'Add a capital letter',
      ru: 'Добавьте заглавную букву',
      kk: 'Бас әріпті қосыңыз',
    };
  }

  // 至少一个数字
  if (!/\d/.test(password)) {
    // return "密码必须包含至少一个数字";
    return {
      en: 'At least one digit',
      ru: 'По крайней мере одна цифра',
      kk: 'Кем дегенде бір сан',
    };
  }

  // 定义特殊字符集
  const fixedSpecialChars = '!@#$%^&*()_+\\-=\\[\\]{}|;:,.<>?';
  // 至少一个特殊字符
  const specialCharsRegex = new RegExp(`[${fixedSpecialChars}]`);
  if (!specialCharsRegex.test(password)) {
    return {
      en: 'At least one special character',
      ru: 'По крайней мере один специальный символ',
      kk: 'Кем дегенде бір ерекше кейіпкер',
    };
  }

  // const widthSpaceRegex = new RegExp(`[${fixedSpecialChars}]`);
  // 含有空格
  if (/\s/.test(password)) {
    console.log(/\b/.test(password));
    return {
      en: 'Spaces are not allowed.',
      ru: 'Пробелы не допускаются.',
      kk: 'Бос орындарға рұқсат етілмейді.',
    };
  }

  // 如果所有条件都满足
  return {
    en: '',
    ru: '',
    kk: '',
  };
};
const validateUserNameIntel = (userName: any): { en: string; ru: string; kk: string } => {
  // 含有数字
  if (/\d/.test(userName)) {
    return {
      en: 'Only uppercase and lowercase letters are allowed',
      ru: 'Пожалуйста, введите имя',
      kk: 'Тек бас және кіші әріптерді енгізуге болады',
    };
  }

  // 如果所有条件都满足
  return {
    en: '',
    ru: '',
    kk: '',
  };
};
const validatePasswordWithSpaceIntel = (password: any): { en: string; ru: string; kk: string } => {
  // 含有空格
  if (/\s/g.test(password)) {
    return {
      en: 'Spaces are not allowed.',
      ru: 'Пробелы не допускаются.',
      kk: 'Бос орындарға рұқсат етілмейді.',
    };
  }

  // 如果所有条件都满足
  return {
    en: '',
    ru: '',
    kk: '',
  };
};

const validateUserNameIntel02 = (userName: any): { en: string; ru: string; kk: string } => {
  // 只允许输入大写小写字母和空格
  // const reg = /^[a-zA-Z\s]{1,20}$/g;
  const reg = /^[A-Za-zА-Яа-яЁёӘәҒғҚқҢңӨөҰұҮүҺһІі\s]+$/g;
  if (!reg.test(userName)) {
    return {
      en: 'Invalid format',
      ru: 'Неверный формат',
      kk: 'Жарамсыз пішім',
    };
  }

  // 如果所有条件都满足
  return {
    en: '',
    ru: '',
    kk: '',
  };
};

const validateEmailOrMobile = function (input: string) {
  if (!input) return 'invalidInput';

  // 手机号正则表达式（7-13位数字）
  const phoneRegex = /^\d{7,13}$/g;

  // 邮箱正则表达式
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/g;

  console.log(input);

  if (phoneRegex.test(input)) {
    return 'mobile';
  } else if (emailRegex.test(input)) {
    return 'email';
  } else {
    return 'invalidInput';
  }
};

// 格式化DocumentNo
// const formatDocumentNo = function (documentNo: string) {
//   // 如果输入的号码已经包含非数字字符，我们假设它已经被格式化，直接返回
//   if (/\D/.test(documentNo)) {
//     return documentNo;
//   }

//   // 如果是纯数字，我们进行格式化
//   const cleaned = documentNo.replace(/\D/, '');
//   console.log(cleaned);

//   if (cleaned.length > 3) {
//     const res = `${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6, 9)} ${cleaned.slice(9)}`;
//     console.log(res);

//     return res;
//   }

//   return cleaned;
// };

const formatDocumentNo = function (value: string, limit?: number) {
  if (!value) return '';
  // Remove non-digit characters
  let number = value.replace(/\D/g, '');

  // Trim to 10 digits if longer
  number = number.substring(0, limit || 20);

  // Apply formatting
  if (number.length > 12) {
    number = number.replace(/(\d{3})(\d{3})(\d{3})(\d{3})(\d{0,5})/, '$1 $2 $3 $4 $5');
  } else if (number.length > 9) {
    number = number.replace(/(\d{3})(\d{3})(\d{3})(\d{0,3})/, '$1 $2 $3 $4');
  } else if (number.length > 6) {
    number = number.replace(/(\d{3})(\d{3})(\d{0,3})/, '$1 $2 $3');
  } else if (number.length > 3) {
    number = number.replace(/(\d{3})(\d{0,3})/, '$1 $2');
  }

  // Update input value
  return number;
};

export {
  validatePasswordIntel,
  validateUserNameIntel,
  formatPhoneNumber,
  validateEmailOrMobile,
  formatPhoneNumberFull,
  formatDocumentNo,
  validateUserNameIntel02,
  validatePasswordWithSpaceIntel,
  formatPhoneNumber11,
};
