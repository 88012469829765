import { useRequest } from 'ahooks';
import NewLoading from '../NewLoading';
import { getProductPublicArticleId } from '@/lib/services/xinwenwenzhang';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { useTranslations } from 'next-intl';

const ArticleDetail = ({
  id,
  onAgree,
  articleContent,
}: {
  id: number | string;
  onAgree?: () => void;
  articleContent?: string;
}) => {
  const t = useTranslations('Footer');
  console.log({ articleContent });

  // 文章详情
  const { data: articleDetail, loading } = useRequest(
    () =>
      getProductPublicArticleId({
        id: +id,
      }),
    {
      manual: !!articleContent,
      cacheKey: 'article-detail',
    },
  );

  return (
    <div className="h-full w-full">
      {loading ? (
        <div className="flex h-full w-full flex-row items-center justify-center">
          <NewLoading />
        </div>
      ) : (
        <div className="flex h-full w-full flex-col items-center justify-between">
          <div className="mb-6 w-full text-center text-lg font-semibold leading-6 text-black max-md:mb-0 max-md:flex max-md:h-14 max-md:flex-row max-md:items-center max-md:justify-center max-md:text-base max-md:font-semibold max-md:text-black">
            {articleDetail?.data?.title}
          </div>
          <ScrollArea
            style={{ height: `calc(100% - 24px - 24px)` }}
            className="w-full text-sm font-normal text-black max-md:hidden"
          >
            {articleDetail?.data?.content ? (
              <div className="h-full w-full" dangerouslySetInnerHTML={{ __html: articleDetail?.data?.content }}></div>
            ) : (
              ''
            )}
          </ScrollArea>
          {articleContent ? (
            <ScrollArea
              style={{ height: `calc(100% - 56px - 12px - 72px)` }}
              className="h-full w-full px-3 text-sm text-black"
            >
              <div className="h-full w-full" dangerouslySetInnerHTML={{ __html: articleContent }}></div>
            </ScrollArea>
          ) : (
            <ScrollArea
              style={{ height: `calc(100% - 56px - 12px - 72px)` }}
              className="h-full w-full px-3 text-sm text-black md:hidden"
            >
              {articleDetail?.data?.content ? (
                <div className="h-full w-full" dangerouslySetInnerHTML={{ __html: articleDetail?.data?.content }}></div>
              ) : (
                ''
              )}
            </ScrollArea>
          )}
          <ScrollArea
            style={{ height: `calc(100% - 56px - 12px - 72px)` }}
            className="h-full w-full px-3 text-sm text-black md:hidden"
          >
            {articleDetail?.data?.content ? (
              <div className="h-full w-full" dangerouslySetInnerHTML={{ __html: articleDetail?.data?.content }}></div>
            ) : (
              ''
            )}
          </ScrollArea>
          <div className="flex h-[72px] w-full cursor-pointer flex-row items-center justify-center md:hidden">
            <Button
              className="h-12 w-[93.6vw] rounded-xl bg-primary text-base font-medium text-black"
              onClick={onAgree}
            >
              {t('agree')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArticleDetail;
