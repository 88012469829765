import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { USER_LOGIN_TOKEN } from '@/shared/constants';
import { useLocalStorageState, useRequest } from 'ahooks';
import { isEmpty } from 'lodash';
import { useCartProvider } from '@/lib/context/CartProvider';
import { getUserWebCustomerProfileShadow } from '../services/gerenzhongxin';
import { message } from 'antd';
import EM from '@/shared/utils/EM';

type ProviderType = {
  isLogin?: boolean;
  token: string | undefined;
  handleLogin?: (token: string) => void;
  forceUpdateToken?: (token: string) => void;
  handleLogout?: () => void;
  setToken?: (token: string) => void;
  isEnabled: boolean;
  updateEmabledStatus?: () => void;
  getAuthToken: (token: string) => void;
  setTokenApp: (token: string) => void;
};
const Context = createContext<ProviderType>({
  isLogin: false,
  token: undefined,
  isEnabled: false,
  getAuthToken: () => {},
  setTokenApp: (token: string) => {
    console.log(token);
  },
});
const AuthProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: {
    getAuthToken: (token?: string) => void;
    setToken: (token?: string) => void;
  };
}) => {
  const { getAuthToken, setToken: setTokenApp } = value;
  const [token, setToken] = useLocalStorageState<{ [key: string]: string }>(
    `${process.env.NEXT_PUBLIC_STORAGE_PREFIX}-${USER_LOGIN_TOKEN}`,
    { defaultValue: undefined, listenStorageChange: true },
  );

  const { shoppingCartSynchronizationAfterLogin, synchronizeShoppingCartCacheData, onlyForLogin } = useCartProvider();

  const [isEnabled, setEnabled] = useState(true); // 初始化为true
  const { runAsync: updateEmabledStatus } = useRequest(getUserWebCustomerProfileShadow, {
    // token会在axios拦截里强制更新
    manual: false,
    onSuccess(res) {
      if (res.code === 0) {
        if (res.data.status === 'ENABLED') {
          setEnabled(true);
        } else {
          setEnabled(false);
        }
      } else {
        setEnabled(false);
        message.error(res.msg);
      }
    },
    onError() {
      setEnabled(false);
    },
  });

  const handleLogin = useCallback(
    async (newToken: string) => {
      console.log({ newToken });
      onlyForLogin.current = true;
      setToken({ data: newToken });
      getAuthToken(newToken);
      await shoppingCartSynchronizationAfterLogin?.();
      await synchronizeShoppingCartCacheData?.();
      await updateEmabledStatus();
      return true;
    },
    [
      getAuthToken,
      onlyForLogin,
      updateEmabledStatus,
      setToken,
      shoppingCartSynchronizationAfterLogin,
      synchronizeShoppingCartCacheData,
    ],
  );

  const handleLogout = useCallback(() => {
    setToken(undefined);
    getAuthToken(undefined);
  }, [getAuthToken, setToken]);

  // Debug: Force update token 用于测试useLocalStorageState的setToekn在更新token是2是否生效
  const forceUpdateToken = useCallback(
    (newToken: string) => {
      console.log('Forcing token update:', newToken);
      setToken({ data: newToken });
    },
    [setToken],
  );

  useEffect(() => {
    const fn = function () {
      updateEmabledStatus();
    };
    EM.on('update_isEnabled_state', fn);
    return () => {
      EM.off('update_isEnabled_state', fn);
    };
  }, [updateEmabledStatus]);

  const exposed = useMemo(() => {
    return {
      isLogin: !isEmpty(token),
      token: token,
      handleLogin,
      handleLogout,
      setToken,
      forceUpdateToken,
      isEnabled,
      updateEmabledStatus,
      getAuthToken,
      setTokenApp,
    };
  }, [
    token,
    handleLogin,
    handleLogout,
    setToken,
    forceUpdateToken,
    isEnabled,
    updateEmabledStatus,
    getAuthToken,
    setTokenApp,
  ]);
  return <Context.Provider value={exposed as any}>{children}</Context.Provider>;
};

export const useAuthProvider = () => useContext(Context);

export default AuthProvider;
