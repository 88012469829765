import { X } from 'lucide-react';
import Image from 'next/image';
// import EM from '@/shared/utils/EM';
// import { useEffect } from 'react';

const CustomModal = ({
  children: Content,
  triggerIcon: TriggerIcon,
  closeIcon: CloseIcon = X,
  isOpen = false,
  setIsOpen,
  callback,
  width = 'w-fit',
}: {
  children?: any;
  triggerIcon?: any;
  closeIcon?: any;
  isOpen?: any;
  setIsOpen: any;
  width?: 'w-fit' | 'w-full';
  callback?: () => void;
}) => {
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    if (callback) {
      callback(); // 关闭弹层的回调
    }
  };

  const handleBackgroundClick = (e: any) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const handleContentClick = (e: any) => {
    e.stopPropagation();
  };

  // // 对外暴露关闭弹窗方法，舍弃，会接收所有的弹层消息，除非在props里还用id区分
  // useEffect(() => {
  //   EM.addListener('CLOSE_MODAL', closeModal);
  //   return () => {
  //     EM.removeListener('CLOSE_MODAL', closeModal);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   EM.addListener('OPEN_MODAL', openModal);
  //   return () => {
  //     EM.removeListener('OPEN_MODAL', openModal);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {TriggerIcon ? (
        <div onClick={openModal} className={`${width} cursor-pointer`}>
          {TriggerIcon}
        </div>
      ) : (
        <button onClick={openModal} className="rounded bg-blue-500 px-4 py-0 text-white hover:bg-blue-600">
          Open Modal
        </button>
      )}

      {isOpen && (
        <div
          className="fixed inset-0 z-[999] flex items-center justify-center bg-black bg-opacity-30"
          onClick={handleBackgroundClick}
        >
          <div className="relative border-0 bg-transparent" onClick={handleContentClick}>
            <div className="absolute right-0 top-10 cursor-pointer" onClick={closeModal}>
              <CloseIcon className="hidden h-10 w-10 rounded-full bg-[#80878E] p-2 text-[#f5f6f6] hover:opacity-90" />
              <Image src={'/images/search/delete.png'} className="size-11" alt="deleteImage" width={44} height={44} />
            </div>
            <div className="rounded-lg bg-transparent p-16 py-10">{Content}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomModal;
