'use client';
// 忘记密码 , 原型profile，前半部分
import { useState } from 'react';
import CustomModal from '@/components/commons/CustomModal';
import EmailOrPhoneInput from './EmailOrPhoneInput/index';
import PasswordSet from './PasswordSet/index';
import VerifyCodeForForgetPass from '../commons/VerifyCode/ForForgetPass';
import Captcha from '../commons/Captcha';
// 修改密码（已登录），不需要滑块验证
// 对比：忘记密码（未登录），需要滑块验证

const ForgetPassword = ({
  triggerIcon,
  initialOpen,
  callback,
  // phone,
  // setForgetPassOpen
  goBack,
}: {
  triggerIcon: any;
  initialOpen?: any;
  callback?: any;
  setForgetPassOpen?: any;
  // phone: string;
  goBack?: () => void;
}) => {
  console.log({ initialOpen });
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen || false);

  const [contentState, setContent] = useState('phone_number_or_email_input'); // 用于控制弹窗内走到下一个流程的内容展示

  const [emailOrMobile, setEmailOrMobile] = useState<string>();
  const [pointJson, setPointJson] = useState<any>();
  const [smsCode, setSmsCode] = useState<string>();

  console.log({ emailOrMobile });

  return (
    <div className="h-fi w-fit">
      <CustomModal
        triggerIcon={triggerIcon || <></>}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        callback={() => {
          setContent('phone_number_or_email_input');
          callback?.();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }}
      >
        {contentState === 'phone_number_or_email_input' && (
          <EmailOrPhoneInput
            setIsOpen={setIsOpen}
            getResult={setEmailOrMobile}
            setContentNext={() => setContent('captcha')}
            goBack={goBack}
          />
        )}
        {contentState === 'captcha' && <Captcha getResult={setPointJson} setContent={() => setContent('msg_verify')} />}
        {contentState === 'msg_verify' && (
          <VerifyCodeForForgetPass
            emailOrMobile={emailOrMobile}
            token={pointJson?.token}
            setContentNext={() => setContent('set_password')}
            getResult={setSmsCode}
            goBack={() => setContent('phone_number_or_email_input')}
          />
        )}
        {contentState === 'set_password' && (
          <PasswordSet smsCode={smsCode} setIsOpen={setIsOpen} emailOrMobile={emailOrMobile} />
        )}
      </CustomModal>
    </div>
  );
};

export default ForgetPassword;
