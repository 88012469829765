import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import VerifyCodeWrap from '../Wrap';
import { useRequest } from 'ahooks';
import { postUserPublicWebCaptchaLogin } from '@/lib/services/wangzhanjiekou';
import { useAuthProvider } from '@/lib/context';

// const COUNT_DOWN_LIMIT = 30;

const VerifyCodeLogin = ({
  email,
  token,
  // setContent,
  goBack,
}: {
  email?: string;
  token?: string;
  setContent?: () => void;
  [k: string]: any;
}) => {
  const t = useTranslations('Registration');

  const [codes, setResult] = useState<string[]>([]);
  const { handleLogin } = useAuthProvider();

  // 验证码登录(web)
  const { loading, run: loginByVerifyCode } = useRequest(postUserPublicWebCaptchaLogin, {
    manual: true,
    onSuccess(res) {
      if (res?.code === 0) {
        if (res.data.token) {
          handleLogin?.(res.data?.token);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    },
  });

  const handleSubmit = () => {
    const code = codes.join('');
    console.log('Submitted code:', code);

    loginByVerifyCode({
      username: email as string, // ?.replace(/\D/g, '') as string,
      captcha: code,
    });
  };

  return (
    <div className={`mx-auto h-fit !w-[428px] rounded-3xl bg-white p-6 shadow-lg`}>
      <h2 className="mb-4 text-center text-lg font-semibold">{t('verifycode')}</h2>
      <p className="mb-0 text-center text-sm font-normal text-[#80878E]">{t('reminder1') + email}</p>
      <p className="mb-0 text-center text-sm font-normal text-[#80878E]">{t('reminder2')}</p>
      <p className="mb-2 text-center text-sm font-normal text-[#80878E]">{t('reminder3')}</p>

      <VerifyCodeWrap
        phoneNumber={email || ''}
        token={token || ''}
        handleSubmit={handleSubmit}
        setResult={setResult}
        goBack={goBack}
        loading={loading}
      />
    </div>
  );
};

export default VerifyCodeLogin;
