/* eslint-disable react/no-unescaped-entities */
import { ScrollArea } from '@/components/ui/scroll-area';
import { useTranslations } from 'next-intl';
import React, { useState, useEffect, useMemo } from 'react';

const COUNT_DOWN_LIMIT = 5;

const PrivacyPolicy = (props: any) => {
  const t = useTranslations('Footer');

  const { termsChecked, setTermsChecked, close, articleContent } = props;
  console.log(termsChecked);

  const [countdown, setCountdown] = useState(COUNT_DOWN_LIMIT);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      setTermsChecked(true);
    }
    return () => clearTimeout(timer);
  }, [countdown, setTermsChecked]);

  const disabled = useMemo(() => {
    // console.log(termsChecked, countdown);
    if (termsChecked) return false;
    return !!countdown;
  }, [countdown, termsChecked]);

  return (
    <div className="relative h-[8/10] p-4 pt-10">
      <div className="hide-extra-scrollbar diy-scrollbar h-[80vh] max-h-[900px] w-[80vw] max-w-[1000px] overflow-y-auto rounded-lg bg-white px-4 py-10 shadow-lg">
        <ScrollArea style={{ height: `calc(100% - 30px )` }} className="h-full w-full px-3 text-sm text-black">
          <div className="h-full w-full" dangerouslySetInnerHTML={{ __html: articleContent }}></div>
        </ScrollArea>

        <div className="absolute bottom-5 left-0 box-border flex w-full justify-center rounded-b-lg p-4">
          <button
            onClick={close}
            className={`w-48 rounded-xl py-2 font-semibold text-white ${
              !disabled ? 'cursor-pointer bg-[#4DBAC0]' : 'cursor-not-allowed bg-gray-400'
            }`}
            disabled={disabled}
          >
            {`${t('agree')} ${!termsChecked && countdown ? countdown + 's' : ''}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
