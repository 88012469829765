import React, { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import VerifyCodeWrap from '../Wrap';
import { validateEmailOrMobile } from '@/components/logreg/utils';
import { formatEmail } from '@/shared/utils/tools';

// 目前用于忘记密码
const VerifyCodeForForgetPass = ({
  emailOrMobile,
  setContentNext,
  getResult,
  goBack,
  token,
}: {
  emailOrMobile?: string;
  setContentNext?: () => void;
  getResult?: (v: any) => void;
  [k: string]: any;
  token: string;
}) => {
  const t = useTranslations('ForForgetPass');

  const [codes, setResult] = useState<string[]>([]);

  const handleSubmit = () => {
    const code = codes.join('');
    console.log('Submitted code:', code);
    getResult?.(code);
    setContentNext?.();
  };

  const [inputType, setInputType] = useState<'email' | 'mobile' | 'invalidInput'>('invalidInput');
  useEffect(() => {
    if (emailOrMobile) {
      setInputType(validateEmailOrMobile(emailOrMobile as string));
    }
  }, [emailOrMobile]);

  return (
    <div className={`mx-auto h-fit !w-[428px] rounded-3xl bg-white p-6 shadow-lg`}>
      <h2 className="mb-4 text-center text-lg font-semibold">{t('varifyCodeTitle')}</h2>
      {inputType === 'mobile' && (
        <>
          <p className="mb-1 text-center text-sm text-black">{t('varifyReminder1')}</p>
          <p className="mb-1 text-center text-sm text-black">{t('varifyReminder2')}</p>
          <p className="mb-4 text-center text-sm text-black">{t('varifyReminder3')}</p>
        </>
      )}
      {inputType === 'email' && (
        <>
          <p className="mb-1 text-center text-sm text-black">{t('varifyReminder4')}</p>
          <p className="mb-1 text-center text-sm text-black">{formatEmail(emailOrMobile)}</p>
        </>
      )}

      <VerifyCodeWrap
        phoneNumber={emailOrMobile as string}
        sendType={inputType}
        noNeedLogin={true}
        handleSubmit={handleSubmit}
        goBack={goBack}
        setResult={setResult}
        butnText1={t('confirm')}
        token={token}
      />
    </div>
  );
};

export default VerifyCodeForForgetPass;
