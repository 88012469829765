// @ts-ignore
/* eslint-disable */
import request from '@/shared/utils/axios';

/** 验证码登录(WEB) 验证码通过短信或邮箱收取

邮箱验证码通[发送邮件验证码(不登录)]接口发送,接口会检查邮箱是否存在,如果不存在,则不会发送验证码,并返回错误消息,前端显示出来给用户看 POST /user/public/web/captcha/login */
export async function postUserPublicWebCaptchaLogin(
  body: {
    /** 手机号或邮箱 */
    username: string;
    /** 通过手机或邮箱获取 */
    captcha: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { token: string } }>('/user/public/web/captcha/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 手机号密码登录 POST /user/public/web/login */
export async function postUserPublicWebLogin(
  body: {
    mobile: string;
    password: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { token: string } }>('/user/public/web/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 用户注册 user-service POST /user/public/web/register */
export async function postUserPublicWebRegister(
  body: {
    mobile: string;
    name: string;
    password: string;
    replayPassword: string;
    ticket: string;
    /** 第三方登录用户唯一标识 */
    thirdUserId?: string;
    thirdPlatformType?: string;
    /** KZ  -哈萨克斯坦
RU -俄罗斯
UZ-乌兹别克斯坦
AM-亚美尼亚
BY-白俄罗斯 */
    country: string;
  },
  options?: { [key: string]: any },
) {
  return request<{ code: number; msg: string; data: { token: string } }>('/user/public/web/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
