'use client';
import { useCallback, useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';
import InputField from '@/components/commons/InputField';
import { useRequest } from 'ahooks';
import { getUserPublicEmailExist, getUserPublicPhoneExist } from '@/lib/services/gonggongjiekou';
import { message } from 'antd';
import { validateEmailOrMobile } from '../../utils';
import AlertIcon from '@/components/cart/AlertIcon';
import { NewLoading } from '@/components/commons';

export default function PhonePopup(props: any) {
  const t = useTranslations('ResetPassword');

  const { setContentNext, getResult, goBack } = props;

  const [emailOrMobile, setPhoneNumberOrEmail] = useState(''); //邮箱或者手机号

  const handlePhoneChange = (e: any) => {
    // const value = e.target.value.replace(/\D/g, '')
    const value = e.target.value;

    setPhoneNumberOrEmail(value);
  };

  const { loading: loadingPhone, runAsync: checkExist } = useRequest(getUserPublicPhoneExist, {
    manual: true,
    onSuccess(res: { code: number; msg: string; data: boolean }) {
      console.log(res);
      if (res.data !== true) {
        message.error(res.msg || 'Mobile number is not registered!');
      }
    },
  });

  const { loading: loadingEmail, runAsync: checkEmailExist } = useRequest(getUserPublicEmailExist, {
    manual: true,
    onSuccess(res: { code: number; msg: string; data: boolean }) {
      console.log(res);
      if (res.data !== true) {
        message.error(res.msg || 'Email is not registered!');
      }
    },
  });

  const handleSend = useCallback(async () => {
    const type = validateEmailOrMobile(emailOrMobile as string);
    let isRegistered = false;
    if (type === 'mobile') {
      const res = await checkExist({ phone: emailOrMobile });
      if (res.data === true) {
        isRegistered = true;
      }
    }
    if (type === 'email') {
      const res = await checkEmailExist({ email: emailOrMobile });
      if (res.data === true) {
        isRegistered = true;
      }
      // if (data.email === emailOrMobile) {
      //   isRegistered = true;
      // } else {
      //   message.error('Email is not registered!');
      // }
    }
    if (isRegistered) {
      getResult(emailOrMobile);
      setContentNext();
    }
  }, [checkEmailExist, checkExist, emailOrMobile, getResult, setContentNext]);

  const [invalidEmail, setEmailInvalid] = useState(false);

  useEffect(() => {
    if (emailOrMobile) {
      if (emailOrMobile.includes('@')) {
        // 邮箱
        const res = validateEmailOrMobile(emailOrMobile);
        if (res === 'email') {
          setEmailInvalid(false);
        } else {
          setEmailInvalid(true);
        }
      } else {
        setEmailInvalid(false);
        if (/\d/g.test(emailOrMobile)) {
          // 手机号
        }
      }
    }
  }, [emailOrMobile]);

  return (
    <div className="inset-0 flex min-h-fit min-w-fit items-center justify-center bg-opacity-50">
      <div className="w-[450px] rounded-3xl bg-white p-6 shadow-lg">
        <h2 className="mb-4 text-center text-lg font-semibold">{t('ask')}</h2>
        <p className="mb-0 text-center text-sm">{t('reminder1')}</p>
        <p className="mb-4 text-center text-sm">{t('reminder2')}</p>

        <div className="w-full space-y-4">
          <InputField
            label={t('label')}
            placeholder="example@email.com"
            value={emailOrMobile}
            onChange={handlePhoneChange}
            withClearIcon={false}
            labelColor="#000"
          />

          {invalidEmail && (
            <div className="flex items-center px-0 py-0" role="alert">
              <AlertIcon />
              <div className="ml-1 text-[12px] text-[#ff3141]"> {t('invalidEmail')}</div>
            </div>
          )}

          <button
            disabled={!emailOrMobile || invalidEmail}
            className={`w-full rounded-xl bg-[#4DBAC0] py-3 text-black transition duration-150 ease-in-out hover:opacity-90 ${!emailOrMobile || invalidEmail ? 'cursor-not-allowed bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : ''}`}
            onClick={handleSend}
          >
            {loadingPhone || loadingEmail ? <NewLoading type="special" /> : t('send')}
          </button>

          <button
            className="w-full rounded-xl bg-[#cce8e9] py-3 text-[#4DBAC0] transition duration-150 ease-in-out hover:opacity-90"
            onClick={goBack}
          >
            {t('back')}
          </button>
        </div>
      </div>
    </div>
  );
}
