import { ReactNode, useEffect, useState } from 'react';
import Script from 'next/script';
import Header from '@/components/commons/Layout/Header';
import Footer from '@/components/commons/Layout/Footer';
import storage from '@/shared/utils/storage';
import { THEME_DARK_MODE } from '@/shared/constants';

interface LayoutProps {
  structured?: { [key: string]: string };
  children: ReactNode;
  hideFooter?: boolean;
  hideHeader?: boolean;
  h5HaveHeader?: boolean;
  h5NoFooter?: boolean;
  inCategoryH5Page?: boolean;
}

export default function Layout({
  children,
  structured,
  h5HaveHeader = false,
  h5NoFooter = false,
  hideFooter = false,
  inCategoryH5Page = false,
}: LayoutProps) {
  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    setDarkMode(storage.get(THEME_DARK_MODE) || false);
  }, []);

  return (
    <div className={`parsec-layout overflow-y-auto ${darkMode ? 'bg-black' : 'bg-[#f5f6f6]'} `}>
      <Script
        id="application/ld+json"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structured) }}
      />
      {/* PC的header */}
      <div className="max-md:hidden">
        <Header />
      </div>
      {/* H5的header */}
      <div className={`md:hidden ${h5HaveHeader ? '' : 'hidden'}`}>
        <Header inCategoryH5Page={inCategoryH5Page} />
      </div>
      {/* PC的main */}
      <main className="pt-[116px] max-md:hidden">{children}</main>
      {/* H5的main */}
      <main
        className={`h-screen w-screen md:hidden ${h5HaveHeader ? 'pt-[52px]' : 'pt-0'} ${h5NoFooter ? 'pb-0' : 'pb-[60px]'}`}
      >
        {children}
      </main>

      {/* PC的footer */}
      <div className="max-md:hidden">{hideFooter ? null : <Footer />}</div>
      {/* H5的footer */}
      <div className={`md:hidden`}>{h5NoFooter ? null : <Footer />}</div>
    </div>
  );
}
