import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleIcon } from '../../commons/GoogleIcon';
import CustomButton from '../../commons/CustomButton';
import { useTranslations } from 'next-intl';
import { NewLoading } from '@/components/commons';

// 定义Google响应的类型
interface GoogleResponse {
  credential: string;
  select_by: string;
}

// 定义组件的props类型
interface GoogleLoginButtonProps {
  // clientId: string;
  onSuccess: (response: GoogleResponse) => void;
  onCancel: () => void;
  loadingOuter?: boolean;
  disabled?: boolean;
}

// 为window.google添加类型定义
declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          initialize: (config: {
            client_id: string;
            cancel_on_tap_outside: boolean;
            auto_select: boolean;
            callback: (response: GoogleResponse) => void;
            cancel: () => void;
          }) => void;
          renderButton: (
            element: HTMLElement | null,
            options: {
              theme: string;
              size: string;
              text: string;
              shape: string;
            },
          ) => void;
          prompt: () => void;
        };
      };
    };
  }
}

const clientId = '300098488811-6mj0huttivnr8tfnd4k81uvh9l6jm7g8.apps.googleusercontent.com';

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ onSuccess, onCancel, loadingOuter, disabled }) => {
  const t = useTranslations('Login');

  const buttonRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState<boolean>(false);

  // const [googleApiLoaded, setGoogleApiLoaded] = useState<boolean>(false);

  const handleGoogleSignIn = useCallback(
    (response: GoogleResponse) => {
      console.log('GoogleResponse获取:', response);
      onSuccess(response);
      setLoading(false);
    },
    [onSuccess],
  );

  const handleGoogleSignInCancel = useCallback(() => {
    console.log('Google登录被取消');
    onCancel();
  }, [onCancel]);

  const initializeGoogleSignIn = useCallback(() => {
    console.log(window.google, buttonRef.current);

    if (window.google && window.google.accounts && buttonRef.current) {
      console.log('initialize');

      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleGoogleSignIn,
        cancel: handleGoogleSignInCancel,
        cancel_on_tap_outside: false,
        auto_select: false,
      });

      window.google.accounts.id.renderButton(buttonRef.current, {
        type: 'standard',
        theme: 'outline',
        size: 'large',
        text: 'signin_with',
        shape: 'rectangular',
        logo_alignment: 'left',
      } as any);

      // window.google.accounts.id.prompt();
    } else {
      console.error('Google API not loaded or button ref not available');
    }
  }, [handleGoogleSignIn, handleGoogleSignInCancel]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    // script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [initializeGoogleSignIn]);

  return (
    <div>
      <div
        className="fixed right-5 top-3"
        ref={buttonRef}
        onClick={() => {
          console.log(123);
          window.google.accounts.id.prompt();
        }}
      ></div>

      <CustomButton
        // ref={buttonRef}
        color="bg-[#F5F6F6] text-black hover:bg-[#f5f6f6]"
        iconSize="18"
        icon={(props: any) => <GoogleIcon {...props} />}
        // onClick={handleGoogleLogin}
        onClick={() => {
          console.dir(buttonRef.current);
          // window.google.accounts.id.prompt();
          // buttonRef.current?.click();
          initializeGoogleSignIn();
        }}
        disabled={loading || loadingOuter || disabled}
      >
        {loading ? <NewLoading /> : t('ContinuewithGoogle')}
      </CustomButton>
    </div>
  );
};

export default GoogleLoginButton;
