// import styles from './assets/index.module.css';
import VerifySlideFixed from './components/verifySlideFixed';

const CaptchaPage = (props: any) => {
  const { setContent, getResult } = props;
  return (
    <div>
      <VerifySlideFixed isSlideShow={true} setContent={setContent} getResult={getResult} />
    </div>
  );
};

export default CaptchaPage;
