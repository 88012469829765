import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useTranslations } from 'next-intl';
import AlertIcon from '@/components/cart/AlertIcon';

const InputField = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  onMouseOut,
  error,
  rows = 1,
  isTextarea = false,
  disabled,
  errWithIcon = false,
  withClearIcon = false,
  className = '',
  labelColor = '#80878E',
  type,
}: any) => {
  const InputComponent = isTextarea ? 'textarea' : 'input';
  const t = useTranslations('Profile');

  const [isFocused, setIsFocused] = useState(false);
  const clearInput = () => {
    onChange?.({ target: { name, value: '' } });
  };
  const handleFocus = () => {
    setIsFocused(true);
    onFocus?.();
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  return (
    <div>
      <div
        className={`relative flex cursor-pointer flex-col rounded-xl border-[1px] bg-[#F5F6F6] px-4 py-1 ${
          error ? 'border-[#ff3141]' : isFocused ? 'border-[#4dbac0]' : 'border-[#cce8e9]'
        } ${className}`}
      >
        <label className={`mb-1 text-sm text-[${labelColor}]`}>{label}</label>
        <div className="flex items-center">
          <InputComponent
            type={type}
            name={name}
            value={value}
            placeholder={isFocused ? '' : placeholder || t('enterhere')}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseOut={onMouseOut}
            rows={rows}
            disabled={disabled}
            className={`${isTextarea ? 'auto' : 'h-8'} w-full resize-none bg-transparent focus:outline-none ${disabled ? 'cursor-not-allowed' : ''}`}
          />
        </div>
        {value && withClearIcon && (
          <button
            type="button"
            onClick={clearInput}
            className="absolute right-2 top-[50%] -translate-y-[50%] rounded-full border-[1px] border-gray-400 text-gray-400 hover:text-gray-600"
          >
            <X size={16} />
          </button>
        )}
      </div>
      {error && (
        <div className="mt-1 flex items-center px-0 py-0" role="alert">
          {errWithIcon && <AlertIcon />}
          <p className="ml-1 mt-0 text-xs text-[#FF3141]">{error}</p>
        </div>
      )}
    </div>
  );
};

export default InputField;
