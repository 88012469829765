type countryEnum = 'kazakhstan' | 'armenia' | 'belarus' | 'uzbekistan' | 'russia';

import React from 'react';
import RussiaFlagIcon from './icons/russia';
import armeniaFlagIcon from './icons/armenia';
import kazakhstanFlagIcon from './icons/kazakhstan';
import belarusFlagIcon from './icons/belarus';
import uzbekistanFlagIcon from './icons/uzbekistan';

const Flag = ({ country }: { country: countryEnum }) => {
  const flags = {
    kazakhstan: kazakhstanFlagIcon,
    armenia: armeniaFlagIcon,
    belarus: belarusFlagIcon,
    uzbekistan: uzbekistanFlagIcon,
    russia: RussiaFlagIcon,
  };

  return <div className="border=[1px] mr-1 h-6 w-6 overflow-hidden rounded-sm border-[#f5f6f6]">{flags[country]}</div>;
};

export default Flag;
