import React, { useState } from 'react';
import { useTranslations } from 'next-intl';
import VerifyCodeWrap from '../Wrap';
import { useRequest } from 'ahooks';
import { postUserPublicWebSmsCodeVerify } from '@/lib/services/gonggongjiekou';
import { formatPhoneNumberFull } from '@/components/logreg/utils';
// import message from '@/components/commons/Message';

// const COUNT_DOWN_LIMIT = 30;

const VerifyCode = ({
  phoneNumber,
  token,
  setContent,
  getResult,
  goBack,
  // thirdUserId,
}: {
  phoneNumber?: string;
  token: string;
  setContent?: () => void;
  getResult?: (v: any) => void;
  [k: string]: any;
}) => {
  const t = useTranslations('Registration');

  const [codes, setResult] = useState<string[]>([]);
  const [submitError, setSubmitError] = useState<boolean>(false);

  // 验证短信验证码(web)
  const { loading, run: SmsCodeVerify } = useRequest(postUserPublicWebSmsCodeVerify, {
    manual: true,
    onSuccess(res) {
      if (res?.code === 0) {
        getResult?.(res.data.ticket);
        setContent?.();
      } else {
        // message.error(res.msg);
        setSubmitError(true);
      }
    },
  });

  const handleSubmit = () => {
    const code = codes.join('');
    console.log('Submitted code:', code);

    SmsCodeVerify({
      phone: phoneNumber?.replace(/\D/g, '') as string,
      smsCode: code,
      operateType: 'REGISTER_VERIFY',
      thirdPlatformType: 'google',
      // thirdUserId, // 不是这里传，是在设置密码后调接口，实现绑定
    });
  };

  return (
    <div className={`mx-auto h-fit !w-[428px] rounded-3xl bg-white p-6 shadow-lg`}>
      <h2 className="mb-4 text-center text-[16px] font-semibold">{t('verifycode')}</h2>
      <p className="mb-0 text-center text-sm font-normal text-[#80878E]">{t('reminder1')}</p>
      <p className="mb-0 text-center text-sm font-normal text-[#80878E]">
        {formatPhoneNumberFull(phoneNumber + '') + ', ' + t('reminder2')}
      </p>
      <p className="mb-2 text-center text-sm font-normal text-[#80878E]">{t('reminder3')}</p>

      <VerifyCodeWrap
        phoneNumber={phoneNumber || ''}
        token={token}
        handleSubmit={handleSubmit}
        setResult={setResult}
        goBack={goBack}
        loading={loading}
        submitError={submitError}
        setSubmitError={setSubmitError}
      />
    </div>
  );
};

export default VerifyCode;
