'use client';

import { setCookie } from 'cookies-next';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { getProductPublicHotWords, postProductPublicUploadSearchImage } from '@/lib/services/gonggongjiekou';
import { useRequest } from 'ahooks';
import { useLocale, useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { startTransition, useEffect, useRef, useState } from 'react';
import DownloadMobileApplications from '../../DownloadMobileApplications';
import { getUserWebMessageOpenApiNewTotal } from '@/lib/services/zhuye';
import { Badge } from '@/components/ui/badge';
import { useAuthProvider, useCartProvider, useCollectProvider } from '@/lib/context';
import { useRouter } from 'next/router';
import { getUserWebCustomerProfile } from '@/lib/services/gerenzhongxin';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import useIntelBaseRoute from '@/lib/hooks/useIntelBaseRoute';
import storage from '@/shared/utils/storage';
import EM from '@/shared/utils/EM';
import { SEARCH_KEYWORDS_FOR_HEADER_INPUT, SEARCH_PAGE_COVER_IMAGE, THEME_DARK_MODE } from '@/shared/constants';
import NewLoading from '../../NewLoading';

const Header = ({
  inCategoryH5Page = false,
  inH5PageShowContent,
  onClickBack,
}: {
  inCategoryH5Page?: boolean;
  inH5PageShowContent?: boolean;
  onClickBack?: (value: boolean) => void;
}) => {
  const searchParamsObj = useSearchParams();
  const keywords = searchParamsObj.get('keywords') ?? '';
  const deleteHotwordIconRef = useRef<HTMLImageElement>(null);
  const navBarInputRef = useRef<HTMLInputElement>(null);
  const { query, replace, push, back } = useRouter();
  const { isLogin } = useAuthProvider();
  const { shoppingCartCount } = useCartProvider();
  const { collectTotal } = useCollectProvider();
  const pathname = usePathname();
  const currentLocale = useLocale();
  const t = useTranslations();
  const [showHot, setShowHot] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>();
  const [showCategoryContent, setShowCategoryContent] = useState<boolean>(false);
  const [openDownLoadApp, setOpenDownLoadApp] = useState<boolean>(false);
  const [value, setValue] = useState<string>(currentLocale);
  const [hotWordsData, setHotWordsData] = useState<{ value: string; type: string }[]>([]); // 搜索热词
  const [isLoginNew, setIsLoginNew] = useState<boolean>(false);
  const { setbaseRoute } = useIntelBaseRoute();
  const [searchImageError, setSearchImageError] = useState<boolean>(false); // 搜索图片上传失败
  const [searchImageErrorMessage, setSearchImageErrorMessage] = useState<string>(''); // 搜索图片上传失败信息

  useEffect(() => {
    setIsLoginNew(!!isLogin);
  }, [isLogin]);

  //今日汇率
  // const { data: exchangeRateData } = useRequest(getProductPublicTodayExchangeRate, {
  //   manual: false,
  //   cacheKey: 'narbar-exchangeRateData',
  // });

  //新消息数量 -- 需要登录
  const { data: newTotalData } = useRequest(getUserWebMessageOpenApiNewTotal, {
    manual: !isLoginNew,
    refreshDeps: [isLoginNew],
    cacheKey: 'narbar-newTotalData',
  });
  //搜索热词
  useRequest(getProductPublicHotWords, {
    manual: false,
    cacheKey: 'narbar-hotWordsData',
    onSuccess: (res) => {
      const hotData = (storage.get(SEARCH_KEYWORDS_FOR_HEADER_INPUT) || [])
        .map((v: any) => ({
          value: v,
          type: 'history',
        }))
        .concat((res?.data || []).map((v) => ({ value: v, type: 'hot' })));
      // 去重
      const uniqueArray = hotData.filter(
        (item: { value: any }, index: any, self: any[]) => index === self.findIndex((t) => t.value === item.value),
      );
      setHotWordsData(uniqueArray);
    },
  });

  // 获取登录后的用户信息，展示头像和名字
  const { data: userInfo } = useRequest(getUserWebCustomerProfile, {
    manual: !isLoginNew,
    refreshDeps: [isLoginNew],
    cacheKey: 'narbar-userInfo',
  });

  // 搜索框点击回车
  useEffect(() => {
    const enterClick = (event: KeyboardEvent) => {
      event.preventDefault();

      if (event.code === 'Enter') {
        setShowHot(false);
        if (searchValue === keywords) return;

        push(`/search?keywords=${searchValue}`);

        storage.del(SEARCH_PAGE_COVER_IMAGE);
      }
    };
    const navBarInput = document.getElementById('navBarInput');
    if (searchValue && navBarInput) {
      navBarInput.addEventListener('keyup', enterClick);
    }

    return () => {
      navBarInput?.removeEventListener('keyup', enterClick);
    };
  }, [keywords, push, searchValue]);

  useEffect(() => {
    return () => {
      setShowHot(false);
    };
  }, []);

  // 上传图片搜索
  const [searchImageLoading, setSearchImageLoading] = useState<boolean>(false); // 上传图片搜索loading

  useEffect(() => {
    // 热词展开的时候，点击输入框和热词删除按钮都不收起热词框
    const handleCloseHotword = (e: any) => {
      if (
        e.target &&
        ((deleteHotwordIconRef?.current && deleteHotwordIconRef.current.contains(e.target as Node)) ||
          (navBarInputRef?.current && navBarInputRef.current.contains(e.target as Node)))
      ) {
        return;
      }
      setTimeout(() => {
        setShowHot(false);
      }, 100);
    };
    if (showHot) {
      document.body.addEventListener('click', handleCloseHotword);
    }

    return () => {
      if (showHot) {
        document.body.removeEventListener('click', handleCloseHotword);
      }
    };
  }, [showHot]);

  useEffect(() => {
    if (pathname === '/category') {
      setShowCategoryContent(true);
    } else {
      setShowCategoryContent(false);
    }
  }, [pathname]);

  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    setDarkMode(storage.get(THEME_DARK_MODE) || false);
  }, []);

  // 页面滚动时，关闭搜索推荐词列表
  useEffect(() => {
    const handleScroll = () => {
      setShowHot(false);
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`fixed left-0 top-0 z-50 w-screen ${darkMode ? 'bg-[#141414]' : 'bg-white'}`}
      data-test="navigation"
    >
      <div className="flex w-full flex-row items-center justify-center backdrop-blur-lg transition-[box-shadow] duration-200 max-sm:hidden">
        <div className="container flex h-[56px] w-full flex-row items-center justify-between">
          <div className="flex items-center justify-center">
            <Link href="/">
              <Image
                width={177}
                height={48}
                src={'/images/navBar/logo.png'}
                className="mr-5 h-[32px] w-auto"
                alt="logoImage"
              />
            </Link>
          </div>
          <div className="flex items-center justify-end">
            <DropdownMenu modal={false}>
              <DropdownMenuTrigger asChild>
                <div className="flex cursor-pointer flex-row items-center justify-start">
                  <Image
                    width={48}
                    height={48}
                    src={`/images/navBar/${darkMode ? 'earth_white' : 'earth'}.png`}
                    className="h-auto w-[24px]"
                    alt="earthImage"
                  />
                  <div className={`ml-2 text-sm font-medium ${darkMode ? 'text-white' : 'text-black'}`}>
                    {currentLocale === 'en' ? 'Eng' : currentLocale === 'ru' ? 'Рус' : 'Қаз'}
                  </div>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="z-[9999] w-[180px] rounded-3xl border-none p-0">
                <DropdownMenuRadioGroup
                  className={`box-border flex w-[180px] cursor-auto flex-col items-center justify-start rounded-3xl border border-solid ${darkMode ? 'border-[rgba(245, 246, 246, 0.44)] bg-[#141414]' : 'border-[#e5e7eb] bg-white'} py-3 pb-3 shadow-2xl`}
                  value={value}
                  onValueChange={(value) => {
                    if (value) {
                      setbaseRoute('/' + value);
                      setValue(value);

                      setCookie('NEXT_LOCALE', value);

                      startTransition(() => {
                        replace({ pathname, query: query }, { pathname, query: query }, { locale: value });
                      });
                    }
                  }}
                >
                  <div
                    className={`mb-3 flex w-[96%] flex-row items-center justify-center px-0 text-center text-base font-medium ${darkMode ? 'text-white' : 'text-black'}`}
                  >
                    {t('Header.chooseLanguage')}
                  </div>
                  {['kk', 'ru', 'en'].map((locale) => {
                    return (
                      <DropdownMenuRadioItem
                        key={locale}
                        value={locale}
                        className="relative flex h-[48px] w-full cursor-pointer flex-row items-center justify-start px-8 py-0 hover:rounded-md hover:bg-[#ebfefe]"
                      >
                        <Image
                          width={48}
                          height={48}
                          src={
                            locale === 'ru'
                              ? '/images/navBar/flag2.png'
                              : locale === 'en'
                                ? '/images/navBar/flag3.png'
                                : '/images/navBar/flag1.png'
                          }
                          className={`mr-3 ${locale === 'en' ? 'h-auto w-[23px]' : 'size-6'}`}
                          alt="image"
                        />
                        <div className={`text-base font-medium ${darkMode ? 'text-white' : 'text-black'}`}>
                          {locale === 'en' ? 'English' : locale === 'ru' ? 'Русский' : 'Қазақша'}
                        </div>
                      </DropdownMenuRadioItem>
                    );
                  })}
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>

            <div
              className="flex cursor-pointer flex-row items-center justify-start"
              onClick={() => {
                setOpenDownLoadApp(true);
              }}
            >
              <Image
                src={'/images/navBar/mobile.png'}
                className="ml-11 mr-3 h-[24px] w-auto"
                width={24}
                height={24}
                alt="mobileImage"
              />
              <div className="text-base font-medium text-primary">{t('Header.downloadMobileApplications')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-row items-center justify-center md:shadow-custom">
        <div
          className={`flex w-full flex-row items-center justify-between md:container ${inCategoryH5Page ? 'max-md:bg-white' : 'max-md:bg-primary'} max-md:px-4 max-md:py-2 md:h-[60px]`}
        >
          <div
            className={`flex flex-1 flex-row items-center ${inCategoryH5Page ? 'justify-between' : 'justify-start'}`}
          >
            <div
              onClick={() => {
                if (pathname === '/category') {
                  back();
                } else {
                  push('/category');
                }
              }}
              style={{
                backgroundColor: showCategoryContent ? 'rgba(77,186,192, 0.24)' : '#4DBAC0',
              }}
              className="flex h-[40px] min-w-[135px] cursor-pointer flex-row items-center justify-center rounded-xl px-3.5 max-md:hidden"
            >
              <div className={`mr-3 text-sm font-medium ${darkMode ? 'text-white' : 'text-black'}`}>
                {t('Header.category')}
              </div>
              <Image
                width={48}
                height={48}
                src={showCategoryContent ? '/images/navBar/close.png' : '/images/navBar/menu.png'}
                className="h-[24px] w-auto"
                alt="menuImage"
              />
            </div>
            {inCategoryH5Page ? (
              <Image
                width={24}
                height={24}
                src={'/images/category/leftArrows.png'}
                className="mr-4 size-6"
                alt="leftArrows"
                onClick={() => {
                  if (inH5PageShowContent && onClickBack) {
                    onClickBack(false);
                  } else {
                    back();
                  }
                }}
              />
            ) : (
              ''
            )}
            <div
              className={`relative flex flex-row items-center justify-between rounded-xl border border-solid border-[#4dbac03d] ${darkMode ? (inCategoryH5Page ? 'bg-black' : 'bg-black max-md:bg-[#141414]') : inCategoryH5Page ? 'bg-[#F5F6F6]' : 'bg-[#F5F6F6] max-md:bg-white'} max-md:h-9 ${inCategoryH5Page ? 'flex-1 max-md:w-fit' : 'max-md:w-[77.6vw]'} max-md:border-none max-md:p-2 max-md:pl-3 md:ml-6 md:h-[40px] md:flex-1 md:px-4 md:py-2.5`}
            >
              <div className="flex w-full cursor-pointer flex-row items-center justify-start">
                <Image
                  width={20}
                  height={20}
                  src={'/images/navBar/search.png'}
                  className="mr-2 h-[20px] w-auto"
                  alt="searchImage"
                  onClick={() => {
                    if (searchValue) {
                      push(`/search?keywords=${searchValue}`);
                      storage.del(SEARCH_PAGE_COVER_IMAGE);
                    }
                  }}
                />
                <div
                  className={`relative h-[20px] text-sm ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'} max-md:w-[203px] md:w-full`}
                >
                  <Input
                    id="navBarInput"
                    ref={navBarInputRef}
                    autoComplete="off"
                    placeholder={t('Header.search')}
                    defaultValue={keywords}
                    value={searchValue}
                    className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform border-none p-0 ${darkMode ? 'text-white' : 'text-black'} caret-[#1890FF] shadow-none focus-visible:border-none focus-visible:ring-0`}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchValue(value);
                      setShowHot(true);
                    }}
                    onFocus={() => setShowHot(true)}
                  />
                </div>
              </div>
              {!showHot ? (
                searchImageLoading ? (
                  <NewLoading className="size-5" />
                ) : (
                  <Image
                    width={40}
                    height={40}
                    src={'/images/navBar/video.png'}
                    className="h-[20px] w-auto cursor-pointer"
                    alt="videoImage"
                    onClick={() => {
                      document?.getElementById('fileInput')?.click();
                    }}
                  />
                )
              ) : (
                ''
              )}
              <input
                type="file"
                id="fileInput"
                className="hidden"
                accept="image/*"
                onChange={(event) => {
                  const files = event.target.files;
                  if (files && files[0]) {
                    setSearchImageLoading(true);
                    postProductPublicUploadSearchImage({}, files[0])
                      .then((response) => {
                        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
                        if (fileInput) {
                          fileInput.value = '';
                        }
                        if (response?.code === 0) {
                          if (response?.data) {
                            push(`/search?imageId=${response?.data}`);
                            storage.del(SEARCH_PAGE_COVER_IMAGE);
                          }
                        } else {
                          // 提示错误信息
                          setSearchImageError(true);
                          setSearchImageErrorMessage(response?.msg);
                          setSearchImageLoading(false);
                        }
                      })
                      .finally(() => setSearchImageLoading(false));
                  }
                }}
              />
              {showHot && hotWordsData && !!hotWordsData.length ? (
                <div
                  className={`absolute left-0 top-11 w-full rounded-xl ${darkMode ? 'bg-black' : 'bg-[#F5F6F6]'} px-4 py-3 shadow`}
                >
                  <div className={`mb-[10px] w-full text-base font-semibold ${darkMode ? 'text-white' : 'text-black'}`}>
                    {t('Header.recentlySearched')}
                  </div>
                  {(hotWordsData ? (hotWordsData ?? []) : []).map((hotWordItem, index) => {
                    return (
                      <div
                        key={index}
                        className="mb-[10px] flex h-6 w-full cursor-pointer flex-row items-center justify-start last-of-type:mb-0"
                      >
                        <Image
                          width={20}
                          height={20}
                          src={'/images/navBar/search1.png'}
                          className="mr-2 h-[20px] w-[20px]"
                          alt="searchImage"
                        />
                        <Link
                          href={`/search?keywords=${hotWordItem.value}`}
                          className={`flex-1 overflow-hidden truncate text-ellipsis whitespace-nowrap text-base ${darkMode ? 'text-white' : 'text-black'}`}
                          onClick={() => {
                            setSearchValue(hotWordItem.value);
                            setShowHot(false);
                            storage.del(SEARCH_PAGE_COVER_IMAGE);
                          }}
                        >
                          {hotWordItem.value}
                        </Link>
                        {hotWordItem.type === 'history' ? (
                          <Image
                            width={24}
                            height={24}
                            ref={deleteHotwordIconRef}
                            src={'/images/navBar/close_gray.png'}
                            className="ml-2 h-6 w-6"
                            alt="searchImage"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (hotWordItem.type === 'history') {
                                const historyData = storage.get(SEARCH_KEYWORDS_FOR_HEADER_INPUT) || [];
                                const tempHistoryData = [...historyData];
                                const historyIndex = tempHistoryData.findIndex((v) => v === hotWordItem.value);
                                tempHistoryData.splice(historyIndex, 1);
                                storage.set(SEARCH_KEYWORDS_FOR_HEADER_INPUT, tempHistoryData);
                              }
                              const tempData = [...hotWordsData];
                              tempData.splice(index, 1);
                              if (tempData.length === 0) {
                                setShowHot(false);
                              }
                              setHotWordsData(tempData);
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={`flex flex-row items-center justify-end md:ml-6 ${inCategoryH5Page ? 'max-md:hidden' : ''}`}>
            {/* 通知 */}
            {isLoginNew ? (
              <Link
                href="/user_center/notifications?tab=promotions"
                className="flex flex-row items-center justify-start"
              >
                <div className="relative">
                  <Image
                    width={24}
                    height={24}
                    src={'/images/navBar/notifications.png'}
                    className="ml-6 mr-2 h-[24px] w-auto cursor-pointer max-md:hidden"
                    alt="notificationsImage"
                  />
                  <Image
                    width={24}
                    height={24}
                    src={'/images/navBar/notifications_white.png'}
                    className="mx-2 size-6 cursor-pointer md:hidden"
                    alt="notificationsImage"
                  />
                  {newTotalData?.data && newTotalData?.data > 0 ? (
                    <Badge
                      className={`absolute right-0 top-[-10px] z-10 ${newTotalData?.data > 10 ? 'px-[2px]' : 'px-[5px]'} text-[10px] leading-3`}
                      variant="destructive"
                    >
                      {newTotalData?.data ? (newTotalData?.data > 0 ? newTotalData?.data : 0) : 0}
                    </Badge>
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className={`cursor-pointer text-sm font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'} max-md:hidden`}
                >
                  {t('Header.notice')}
                </div>
              </Link>
            ) : (
              <div
                onClick={() => {
                  EM.emit('toLogin');
                }}
                className="flex flex-row items-center justify-start"
              >
                <Image
                  width={24}
                  height={24}
                  src={'/images/navBar/notifications.png'}
                  className="ml-6 mr-2 h-[24px] w-auto cursor-pointer max-md:hidden"
                  alt="notificationsImage"
                />
                <Image
                  width={24}
                  height={24}
                  src={'/images/navBar/notifications_white.png'}
                  className="mx-2 size-6 cursor-pointer md:hidden"
                  alt="notificationsImage"
                />
                <div
                  className={`cursor-pointer text-sm font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'} max-md:hidden`}
                >
                  {t('Header.notice')}
                </div>
              </div>
            )}
            {/* 收藏 */}
            {isLoginNew ? (
              <Link href="/favorites" className="flex w-fit justify-start max-md:hidden">
                <div className="relative ml-6 mr-2">
                  <Image
                    width={48}
                    height={48}
                    src={`/images/navBar/${pathname === '/favorites' ? 'like_filled.svg' : 'like.png'}`}
                    className="h-[24px] w-auto cursor-pointer"
                    alt="likeImage"
                  />
                  {collectTotal ? (
                    collectTotal >= 10 ? (
                      <Badge
                        className={`absolute right-[-10px] top-[-10px] z-10 px-[2px] text-[10px] leading-3`}
                        variant="destructive"
                      >
                        {collectTotal || 0}
                      </Badge>
                    ) : (
                      <Badge
                        className={`absolute right-[-10px] top-[-10px] z-10 px-[5px] text-[10px] leading-3`}
                        variant="destructive"
                      >
                        {collectTotal || 0}
                      </Badge>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div
                  className={`cursor-pointer text-sm font-medium ${pathname === '/favorites' ? 'text-primary' : darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                >
                  {t('Header.selected')}
                </div>
              </Link>
            ) : (
              <div
                onClick={() => {
                  EM.emit('toLogin');
                }}
                className="flex w-fit justify-start max-md:hidden"
              >
                <Image
                  width={48}
                  height={48}
                  src={`/images/navBar/${pathname === '/favorites' ? 'like_filled.svg' : 'like.png'}`}
                  className="ml-6 mr-2 h-[24px] w-auto cursor-pointer"
                  alt="likeImage"
                />
                <div
                  className={`cursor-pointer text-sm font-medium ${pathname === '/favorites' ? 'text-primary' : darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}
                >
                  {t('Header.selected')}
                </div>
              </div>
            )}
            {/* 购物车 */}
            <Link href={'/cart'} className="flex flex-row items-center justify-start max-md:hidden">
              <div className="relative z-0">
                <Image
                  width={48}
                  height={48}
                  src={`/images/navBar/${pathname === '/cart' ? 'shopping_filled.svg' : 'shopping.png'}`}
                  className="ml-6 mr-2 h-[24px] w-auto cursor-pointer"
                  alt="shoppingImage"
                />
                {shoppingCartCount ? (
                  <Badge
                    className={`absolute right-0 top-[-10px] z-10 ${shoppingCartCount > 10 ? 'px-[2px]' : 'px-[5px]'} text-[10px] leading-3`}
                    variant="destructive"
                  >
                    {shoppingCartCount}
                  </Badge>
                ) : (
                  ''
                )}
              </div>
              <div
                className={`cursor-pointer text-sm font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'} ${pathname === '/cart' ? '!text-primary' : ''}`}
              >
                {t('Header.shoppingCart')}
              </div>
            </Link>

            {/* 头像名称 */}
            {isLoginNew ? (
              <Link href="/user_center/profiles" className="flex flex-row items-center justify-start max-md:hidden">
                <Image
                  width={48}
                  height={48}
                  src={userInfo?.data?.avatar || '/images/navBar/users.png'}
                  className="ml-6 mr-2 size-6 cursor-pointer rounded-full object-cover"
                  alt="usersImage"
                />
                <div className={`cursor-pointer text-sm font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}>
                  {userInfo?.data?.name || t('Header.my')}
                </div>
              </Link>
            ) : (
              <div
                onClick={() => {
                  EM.emit('toLogin');
                }}
                className="flex flex-row items-center justify-start max-md:hidden"
              >
                <Image
                  width={48}
                  height={48}
                  src={'/images/navBar/users.png'}
                  className="ml-6 mr-2 size-6 cursor-pointer rounded-full object-cover"
                  alt="usersImage"
                />
                <div className={`cursor-pointer text-sm font-medium ${darkMode ? 'text-[#A3A3A3]' : 'text-[#80878E]'}`}>
                  {t('Header.my')}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* 下载APP 弹窗 */}
      <Dialog open={openDownLoadApp} onOpenChange={(value) => setOpenDownLoadApp(value)}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <div
            className={`flex w-[274px] flex-col items-center justify-center rounded-3xl ${darkMode ? 'bg-[#141414]' : 'bg-white'} p-6`}
          >
            <DownloadMobileApplications />
          </div>
        </DialogContent>
      </Dialog>
      {/* 识别图片接口失败的提示弹窗 */}
      {searchImageError && (
        <Dialog open={searchImageError} onOpenChange={(value) => setSearchImageError(value)}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <div className="h-auto w-[428px] rounded-[14px] bg-white max-md:w-screen">
              <div
                style={{ borderColor: 'rgba(60, 60, 67, 0.36)' }}
                className={`flex h-[72px] w-full flex-row items-center justify-center border-b border-solid px-11 text-center text-base font-medium leading-6 text-black`}
              >
                {searchImageErrorMessage}
              </div>
              <div
                className={`flex h-[72px] w-full cursor-pointer flex-row items-center justify-center text-base leading-6 text-[#1890FF]`}
                onClick={() => setSearchImageError(false)}
              >
                {t('Product.understood')}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </nav>
  );
};

export default Header;
