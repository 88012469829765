import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import { useLocale, useTranslations } from 'next-intl';
import message from '@/components/commons/Message/index';
import { postUserPublicWebRegister } from '@/lib/services/wangzhanjiekou';
import { validatePasswordIntel, validateUserNameIntel } from '../../utils';
import AlertIcon from '@/components/cart/AlertIcon';
import { NewLoading } from '@/components/commons';
import CustomModal from '@/components/commons/CustomModal';
import CustomCheckbox from '../../commons/CustomCheckbox';
import Policy from './PolicyAndTerm';
import InputField from '@/components/commons/InputField';
import EyeOff from '../../icons/EyeOff';
import Eye from '../../icons/Eye';
import { useAuthProvider } from '@/lib/context';
import { useRouter } from 'next/router';
const NamePlaceHolers = {
  ru: 'Иван Иванов',
  en: 'Ivan Ivanov',
  kk: 'Иван Иванов',
};

const PasswordInput = ({ phoneNumber, ticket, abbrCountry, setNext, thirdUserId }: any) => {
  const t = useTranslations('Registration');
  const router = useRouter();

  const { handleLogin } = useAuthProvider();

  const currentLocale = useLocale();

  const [termsChecked, setTermsChecked] = useState(false);
  const [isTermLayerOpen, setTermLayerOpen] = useState<boolean>(false);

  // 注册
  const { loading, run: toRegister } = useRequest(postUserPublicWebRegister, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      if (res.data?.token) {
        handleLogin?.(res.data?.token);
        setTimeout(() => {
          setNext();
        }, 1000);
      } else {
        message.error(res.msg || t('failed'), 2000);
      }
    },
  });

  const validatePassword = (pass: string) => {
    // Basic password strength check (you can enhance this)
    return pass.length >= 8 && /[A-Z]/.test(pass) && /[a-z]/.test(pass) && /[0-9]/.test(pass);
  };

  const initRef = useRef(false);

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [replayPassword, setReplayPassword] = useState('');

  const [isSame, setIsSame] = useState<boolean>(true);
  const [blur, manageBlur] = useState<number>(1);

  const [nameVerifyText, setNameVerifyText] = useState('');
  const [passwordVerifyText, setPasswordVerifyText] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = (field: string) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleNameChange = (e: any) => {
    // const value = e.target.value.replace(/\D/g, '')
    const value = e.target.value;
    setName(value);

    const validateRes = validateUserNameIntel(value);

    setNameVerifyText(validateRes[currentLocale as 'en' | 'ru' | 'kk']);
  };

  const handlePassChange = (e: any) => {
    // const value = e.target.value.replace(/\D/g, '')
    const value = e.target.value.trim();
    setPassword(value);
    const validateRes = validatePasswordIntel(value);
    // console.log(validateRes);
    setPasswordVerifyText(validateRes[currentLocale as 'en' | 'ru' | 'kk']);
  };

  const handleReplayPassChange = (e: any) => {
    // const value = e.target.value.replace(/\D/g, '')
    const value = e.target.value;
    setReplayPassword(value);
    if (value.length >= password.length) {
      // setIsSame(value === password);
    }
  };

  useEffect(() => {
    if (name && password && replayPassword) {
      initRef.current = true;
    }
  }, [name, password, replayPassword]);

  const disabled = useMemo(() => {
    if (!initRef.current) {
      return true;
    }

    return !isSame || loading || !!passwordVerifyText || !termsChecked || !!nameVerifyText;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSame, loading, passwordVerifyText, termsChecked, blur, nameVerifyText]);

  return (
    <div className="relative inset-0 flex min-w-fit items-center justify-center bg-opacity-50">
      <div className="w-[420px] rounded-3xl bg-white p-[20px] shadow-lg">
        <h2 className="mb-4 text-center text-2xl font-bold">{t('toRegister')}</h2>

        <div className="w-[380px] space-y-4">
          <InputField
            label={t('name')}
            value={name}
            onChange={handleNameChange}
            placeholder={NamePlaceHolers[router.locale as 'en' | 'ru' | 'kk']}
            labelColor="#000"
          />
          {nameVerifyText && (
            <div className="flex items-center px-0 py-0" role="alert">
              <AlertIcon />
              <div className="ml-1 text-[12px] text-[#ff3141]">{nameVerifyText}</div>
            </div>
          )}

          <div className="relative">
            <InputField
              label={t('password')}
              type={showPassword ? 'text' : 'password'}
              placeholder="********"
              labelColor="#000"
              value={password}
              onChange={handlePassChange}
              onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const value = e.target.value;
                if (replayPassword) {
                  setIsSame(value === replayPassword);
                }
              }}
              onFocus={() => {
                // setIsSame(false);
              }}
            />

            <button
              type="button"
              onClick={() => togglePasswordVisibility('password')}
              className="absolute right-4 top-[50%] -translate-y-[50%] text-[#9ba1a8]"
            >
              {showPassword ? <Eye /> : <EyeOff />}
            </button>
          </div>

          {passwordVerifyText && (
            <div className="flex items-center px-0 py-0" role="alert">
              <AlertIcon />
              <div className="ml-1 text-[12px] text-[#ff3141]">{passwordVerifyText}</div>
            </div>
          )}

          <div className="relative">
            <InputField
              label={t('inputagain')}
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="********"
              labelColor="#000"
              value={replayPassword}
              onChange={handleReplayPassChange}
              onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const value = e.target.value;
                if (password) {
                  setIsSame(value === password);
                }
              }}
              onFocus={() => {
                // setIsSame(false);
              }}
              onMouseOut={() => manageBlur((v) => v + 1)}
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility('confirm')}
              className="absolute right-4 top-[50%] -translate-y-[50%] text-[#9ba1a8]"
            >
              {showConfirmPassword ? <Eye /> : <EyeOff />}
            </button>
          </div>

          {!isSame && (
            <div className="flex items-center px-0 py-0" role="alert">
              <AlertIcon />
              <div className="ml-1 text-[12px] text-[#ff3141]"> {t('notsametext')}</div>
            </div>
          )}

          <button
            className={`w-full rounded-xl bg-[#4DBAC0] py-3 text-black transition duration-150 ease-in-out hover:opacity-85 ${disabled ? '!bg-[var(--primary-clor-gray3)] !text-[var(--theme-gray-1)]' : ''}`}
            disabled={disabled}
            onClick={() => {
              if (!termsChecked) {
                message.error(t('checkTermsReminder'), 2000);
                return;
              }

              if (
                name &&
                ticket &&
                abbrCountry &&
                phoneNumber &&
                validatePassword(password) &&
                password === replayPassword
              ) {
                toRegister({
                  mobile: phoneNumber.replace(/\D/g, ''),
                  password,
                  replayPassword,
                  name,
                  ticket: ticket, // 验证短信验证码通过后返回的临时ticket
                  country: abbrCountry,
                  thirdUserId,
                  thirdPlatformType: thirdUserId ? 'google' : undefined,
                } as any);
              }
            }}
          >
            {loading ? <NewLoading type="special" /> : t('enter')}
          </button>
        </div>
        <div className="w-fit" onClick={() => setTermLayerOpen(true)}>
          <CustomCheckbox
            checked={termsChecked}
            label={
              <div className="flex flex-wrap justify-start">
                <span className="my-0 mr-2">{t('termTitle1')}</span>
                <a className="my-0 mr-2 text-[#1890FF] hover:underline">{t('termTitle2')}</a>
                <span className="my-0 mr-2">{t('termTitle3')}</span>
                <a className="my-0 text-[#1890FF] hover:underline">{t('termTitle4')}</a>
              </div>
            }
            handleClick={() => setTermLayerOpen(true)}
          />
        </div>
      </div>

      <CustomModal
        triggerIcon={<></>}
        isOpen={isTermLayerOpen}
        setIsOpen={setTermLayerOpen}
        callback={() => {
          // 关闭弹层的回调
          // setContent('phone_number_input');
        }}
      >
        <Policy termsChecked={termsChecked} setTermsChecked={setTermsChecked} close={() => setTermLayerOpen(false)} />
      </CustomModal>
    </div>
  );
};

export default PasswordInput;
