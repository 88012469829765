import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { useRequest } from 'ahooks';
import { getUserPublicWebFrontpageSetting } from '@/lib/services/zhuye';
import NewLoading from '../NewLoading';
import { useEffect, useState } from 'react';
import storage from '@/shared/utils/storage';
import { THEME_DARK_MODE } from '@/shared/constants';

const DownloadMobileApplications = () => {
  const t = useTranslations('Footer');
  // 首页配置项接口
  const { data: settingData, loading } = useRequest(getUserPublicWebFrontpageSetting, {
    manual: false,
    cacheKey: 'settingData-qrcode',
  });

  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    setDarkMode(storage.get(THEME_DARK_MODE) || false);
  }, []);

  return (
    <div className="w-[225px]">
      <div className={`mb-6 w-[225px] text-center text-base font-semibold ${darkMode ? 'text-white' : 'text-black'}`}>
        {t('appName')} UMAY Shop
      </div>
      <div
        style={{ borderColor: 'rgba(50, 129, 129, 0.06)' }}
        className="box-border flex w-[225px] flex-col items-center justify-start rounded-[20px] border border-solid pb-3 pt-2"
      >
        <div className="mb-2 rounded-lg border-4 border-solid border-[#4DBAC0]">
          <div className="flex h-[153px] w-[153px] flex-row items-center justify-center">
            {loading ? (
              <div className="flex h-[137px] w-[137px] flex-row items-center justify-center">
                <NewLoading />
              </div>
            ) : (
              <>
                {settingData?.data?.qrCode ? (
                  <Image
                    src={settingData?.data?.qrCode}
                    width={137}
                    height={137}
                    className="h-[137px] w-[137px]"
                    alt="qrCodeImage"
                  />
                ) : (
                  <div className="flex h-[137px] w-[137px] flex-row items-center justify-center bg-[#80878E]"></div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={`mb-3 w-3/4 text-center text-sm ${darkMode ? 'text-white' : 'text-black'}`}>
          {t('downloadText')}
        </div>
        <div className="flex w-full flex-row items-center justify-center">
          <div
            className={`mr-3 flex h-[44px] w-[94px] cursor-pointer flex-row items-center justify-center rounded-xl ${darkMode ? 'bg-white' : 'bg-black'}`}
          >
            <Image
              src={`/images/footer/${darkMode ? 'appStore_dark' : 'appStore'}.png`}
              className="h-auto w-4"
              width={16}
              height={16}
              alt="appStoreImage"
              onClick={() => window.open(settingData?.data?.googlePlayUrl)}
            />
          </div>
          <div
            className={`flex h-[44px] w-[94px] cursor-pointer flex-row items-center justify-center rounded-xl ${darkMode ? 'bg-white' : 'bg-black'}`}
          >
            <Image
              src={'/images/footer/googlePlay.png'}
              className="h-auto w-4"
              alt="googlePlayImage"
              width={16}
              height={16}
              onClick={() => window.open(settingData?.data?.appStoreUrl)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadMobileApplications;
