import React, { createContext, useContext } from 'react';
import { getProductPublicProductCategoryAll } from '@/lib/services/gonggongjiekou';
import { useRequest } from 'ahooks';

type ProviderType = {
  handleRefreshCategory?: () => void;
  productCategory?: {
    id: number;
    parentCateId: number;
    categoryName: string;
    categoryNameCN: string;
    image: string;
    level: number;
    childen: API.WebProductCategoryVo[];
  }[];
};

const Context = createContext<ProviderType>({
  productCategory: [],
});

const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, refresh } = useRequest(getProductPublicProductCategoryAll);
  return (
    <Context.Provider
      value={{
        productCategory: (data?.data ?? []) as any,
        handleRefreshCategory: refresh,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useConfigProvider = () => useContext(Context);

export default ConfigProvider;
