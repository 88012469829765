/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import { NewLoading } from '@/components/commons';
import { useRequest } from 'ahooks';
import { getProductPublicMultipleLangArticle } from '@/lib/services/gonggongjiekou';
import { message } from 'antd';
import { useRouter as useNextRouter } from 'next/router';

const PolicyAndTerm = (props: any) => {
  const { termsChecked, setTermsChecked, close, loading } = props;

  const routerInstance = useNextRouter();

  const [contentState, setContent] = useState('TermsOfUse'); // 用于控制弹窗展示哪个协议内容: TermsOfUse PrivacyPolicy

  const { data: articleUse } = useRequest(
    () =>
      getProductPublicMultipleLangArticle({
        type: 'USER_PROTOCOL',
        lang: (routerInstance.locale + '').toUpperCase(),
      }),
    {
      // 类型: REFUND_POLICY  退换货政策 USER_PROTOCOL 用户注册协议  PRIVACY_POLICY 隐私政策 PUBLIC_OFFER 公开要约
      // 语言: RU 俄语  EN 英语  KZT 哈萨克斯坦语
      manual: false,
      onSuccess: (res) => {
        if (res.code !== 0) {
          message.error(res.msg);
        }
      },
    },
  );
  const { data: articlePrivacy } = useRequest(
    () =>
      getProductPublicMultipleLangArticle({
        type: 'PRIVACY_POLICY',
        lang: (routerInstance.locale + '').toUpperCase(),
      }),
    {
      // 类型: REFUND_POLICY  退换货政策 USER_PROTOCOL 用户注册协议  PRIVACY_POLICY 隐私政策 PUBLIC_OFFER 公开要约
      // 语言: RU 俄语  EN 英语  KZT 哈萨克斯坦语
      manual: false,
      onSuccess: (res) => {
        if (res.code !== 0) {
          message.error(res.msg);
        }
      },
    },
  );

  return (
    <>
      {loading ? (
        <div className="flex h-[80vh] w-[80vw] flex-col rounded-3xl bg-white pt-10 shadow-lg">
          <NewLoading />
        </div>
      ) : (
        <div>
          {contentState === 'TermsOfUse' && (
            <TermsOfUse
              articleContent={articleUse?.data.content}
              termsChecked={termsChecked}
              close={close}
              setContentNext={() => setContent('PrivacyPolicy')}
            />
          )}
          {contentState === 'PrivacyPolicy' && (
            <PrivacyPolicy
              articleContent={articlePrivacy?.data.content}
              termsChecked={termsChecked}
              close={() => {
                setContent('TermsOfUse');
                close();
              }}
              setTermsChecked={setTermsChecked}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PolicyAndTerm;
